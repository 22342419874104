import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAuthStore,
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";
import { Radio, Space } from "antd";

const Layout = () => {
  const layout = useChartStore((state) => state.layout);
  const setLayout = useChartStore((state) => state.setLayout);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const [options, setOptions] = useState([]);

  useEffect(
    () =>
      setOptions([
        { value: "vertical", label: `${commonTitles.vertical}` },
        { value: "horizontal", label: `${commonTitles.horizontal}` },
      ]),
    [language, commonTitles]
  );

  return (
    <FormControl sx={{ color: "rgb(8, 8, 46)", mt: 2 }}>
      <FormLabel
        sx={{ color: "rgb(84, 190, 243)", fontSize: "12px", mb: "5px" }}
        id="demo-row-radio-buttons-group-label"
      >
        {commonTitles.layout}
      </FormLabel>
      <Radio.Group
        onChange={(e) => setLayout(e.target.value)}
        value={layout}
        size="small"
      >
        <Space direction="horizontal" style={{ gap: 2 }}>
          {options.map(({ value, label }) => (
            <Radio key={value} value={value} size="small">
              <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{label}</p>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </FormControl>
  );
};

export default Layout;
