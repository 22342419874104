import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAuthStore,
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";
import { Radio, Space } from "antd";
import Flex from "../../../../helpers/components/Flex";

const Interpolation = () => {
  const showTrendline = useChartStore((state) => state.showTrendline);
  const setShowTrendline = useChartStore((state) => state.setShowTrendline);

  const trendline = useChartStore((state) => state.trendline);
  const setTrendline = useChartStore((state) => state.setTrendline);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const [options, setOptions] = useState([]);
  const [showTrendlinepOtions, setShowTrendlinepOtions] = useState([]);

  useEffect(() => {
    setOptions([
      { value: "linear", label: `${commonTitles.linear}` },
      { value: "exponential", label: `Εκθετική` },
    ]);
    setShowTrendlinepOtions([
      { value: false, label: `Όχι` },
      { value: true, label: `Ναι` },
    ]);
  }, [language, commonTitles]);

  return (
    <Flex flexDirection={"column"} style={{ gap: 20 }}>
      <FormControl sx={{ color: "rgb(8, 8, 46)", mt: 2, fontSize: "14px" }}>
        <FormLabel
          sx={{ color: "rgb(84, 190, 243)", fontSize: "12px", mb: "10px" }}
          id="demo-row-radio-buttons-group-label"
        >
          {"Εμφάνιση Καμπύλης Τάσης"}
        </FormLabel>
        <Radio.Group
          onChange={(e) => setShowTrendline(e.target.value)}
          value={showTrendline}
        >
          <Space direction="horizontal" style={{ gap: 2 }}>
            {showTrendlinepOtions.map(({ value, label }) => (
              <Radio key={value} value={value}>
                <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {label}
                </p>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </FormControl>
      {showTrendline && (
        <FormControl sx={{ color: "rgb(8, 8, 46)", mt: 2, fontSize: "14px" }}>
          <FormLabel
            sx={{ color: "rgb(84, 190, 243)", fontSize: "12px", mb: "10px" }}
            id="demo-row-radio-buttons-group-label"
          >
            {"Καμπύλη Τάσης"}
          </FormLabel>
          <Radio.Group
            onChange={(e) => setTrendline(e.target.value)}
            value={trendline}
          >
            <Space direction="horizontal" style={{ gap: 2 }}>
              {options.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                    {label}
                  </p>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </FormControl>
      )}
    </Flex>
  );
};

export default Interpolation;
