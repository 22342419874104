import "../../../css/ultimateTable.css";
import { Modal, Button } from "antd";
import { useState } from "react";
import KPIsModalContent from "./KPIsModalContent";
import { useCommonTitlesStore } from "../../../../../store/useStores";

const KPIsModal = ({ visible, kpis, onCancel }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Modal
      title={"KPIs"}
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      width="max-content"
      style={{ marginTop: -60.5 }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
      ]}
    >
      <KPIsModalContent kpis={kpis} />
    </Modal>
  );
};

export default KPIsModal;
