import { Tooltip, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";

const EraseButton = ({ updateEraseButton, invertChartVisibility }) => {
  const { width } = useWindowDimensions();
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const setChartType = useChartStore((state) => state.setChartType);
  const setXAxisField = useChartStore((state) => state.setXAxisField);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);
  const setGroupField = useChartStore((state) => state.setGroupField);
  const setGrouping = useChartStore((state) => state.setGrouping);
  const setChartBase64 = useChartStore((state) => state.setChartBase64);

  return (
    <Tooltip
      placement={width < 1525 ? "right" : "bottom"}
      title={commonTitles["CO|1|CLEARALL"]}
    >
      <Button
        style={{
          backgroundColor: "red",
          color: "#fff",
          //marginLeft: "5px",
        }}
        shape="round"
        icon={<CloseOutlined />}
        onClick={() => {
          updateEraseButton(false);
          setChartType("BarChart");
          setXAxisField(null);
          setDisplayFunction(null);
          setGroupField(null);
          setGrouping(null);
          setChartBase64("");
          invertChartVisibility();
        }}
      />
    </Tooltip>
  );
};

export default EraseButton;
