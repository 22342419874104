import { Tooltip, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const SettingsButton = ({
  chartParamMenuVisible,
  changeChartParamMenuVisible,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const chartType = useChartStore((state) => state.chartType);
  const { width } = useWindowDimensions();

  return (
    <Tooltip
      placement={width < 1525 ? "right" : "bottom"}
      title={commonTitles["CO|1|CONFIG"]}
    >
      <Button
        style={{
          backgroundColor:
            chartParamMenuVisible &&
            chartType !== "CandlestickChart" &&
            "rgb(8, 8, 46)",
          color:
            chartParamMenuVisible && chartType !== "CandlestickChart"
              ? "#fff"
              : "rgb(8, 8, 46)",
          width: 50,
          paddingTop: 5,
          paddingBottom: 5,
        }}
        disabled={chartType === "CandlestickChart" && true}
        shape="round"
        icon={<SettingOutlined />}
        onClick={() => changeChartParamMenuVisible(!chartParamMenuVisible)}
      />
    </Tooltip>
  );
};

export default SettingsButton;
