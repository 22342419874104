import { Tooltip, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";
import { useCommonTitlesStore } from "../../../../store/useStores";

const CalculateButton = ({ updateEraseButton, invertChartVisibility }) => {
  const { width } = useWindowDimensions();
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const getData = async () => {
    console.log("get data from backend");
  };

  return (
    <Tooltip
      placement={width < 1525 ? "right" : "bottom"}
      title={commonTitles["CO|1|CALCULATE"]}
    >
      <Button
        style={{
          backgroundColor: "rgb(8, 8, 46)",
          color: "#fff",
        }}
        shape="round"
        icon={<CheckOutlined />}
        onClick={() => {
          getData();
          updateEraseButton(true);
          invertChartVisibility();
        }}
      />
    </Tooltip>
  );
};

export default CalculateButton;
