import { useChartStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import { Select } from "antd";
import { Chart } from "react-google-charts";
import ChartSettingsDisplayer from "../chartSettingsDisplayer/ChartSettingsDisplayer";
import Radial from "./radial/Radial";
import ChartTable from "./chartTable/ChartTable";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const ChartDisplayer = ({
  pieChartXField,
  setPieChartXField,
  candlestickChartField,
  setCandlestickChartField,
  finalChartType,
  data,
  columns,
  chartOptions,
  chartParamMenuVisible,
  changeChartParamMenuVisible,
  staticOptions,
}) => {
  const chartType = useChartStore((state) => state.chartType);
  const radialDisplay = useChartStore((state) => state.radialDisplay);
  const { width } = useWindowDimensions();

  if (chartType === "PieChart" && radialDisplay && data?.[0]?.data?.length) {
    return (
      <Flex
        key={width}
        flexDirection={"row"}
        style={{
          width: "95%",
          alignItems: "center",
          height: "60vh",
        }}
      >
        {data?.length && (
          <Radial data={data} columns={columns} staticOptions={staticOptions} />
        )}

        {chartParamMenuVisible && (
          <ChartSettingsDisplayer
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
          />
        )}
      </Flex>
    );
  }

  if (chartType === "PieChart" && !radialDisplay) {
    return (
      <Flex
        key={width}
        flexDirection={"column"}
        style={{ width: "95%", alignItems: "center" }}
      >
        <Flex
          flexDirection={"row"}
          style={{
            width: "95%",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Flex
            flexDirection={"row"}
            style={{ width: "95%", alignItems: "center" }}
          >
            <div className="select">
              <Select
                style={{
                  width: "8rem",
                  marginLeft: -10,
                  marginRight: 0,
                  marginTop: -50,
                  marginBottom: 10,
                }}
                showSearch={true}
                filterOption={(iv, op) => {
                  let opToUse = op.children ? op.children : "";
                  return opToUse.toLowerCase().includes(iv.toLowerCase());
                }}
                dropdownStyle={{
                  minWidth: "max-content",
                  paddingLeft: 5,
                  paddingRight: 5,
                  overflowY: "auto",
                }}
                onChange={(e) => setPieChartXField(e)}
                defaultValue={pieChartXField}
                virtual={false}
              >
                <Option className="option" key={""} value={""} />
                {["2020", "2021", "2022"].map((item) => (
                  <Option className="option" key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>

            <Chart
              chartType={finalChartType}
              width={"95%"}
              height={!chartParamMenuVisible ? "60vh" : "55vh"}
              data={data}
              options={chartOptions}
            />
          </Flex>
          {chartParamMenuVisible && (
            <ChartSettingsDisplayer
              chartParamMenuVisible={chartParamMenuVisible}
              changeChartParamMenuVisible={changeChartParamMenuVisible}
            />
          )}
        </Flex>
      </Flex>
    );
  }

  if (chartType === "CandlestickChart" && data?.[0]?.length) {
    return (
      <Flex
        key={width}
        flexDirection={"column"}
        style={{ width: "95%", alignItems: "center" }}
      >
        <Flex
          flexDirection={"row"}
          style={{ width: "95%", alignItems: "center" }}
        >
          <div className="select">
            <Select
              style={{
                width: "8rem",
                marginLeft: -10,
                marginRight: 0,
                marginTop: -50,
                marginBottom: 10,
              }}
              showSearch={true}
              filterOption={(iv, op) => {
                let opToUse = op.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              dropdownStyle={{
                minWidth: "max-content",
                paddingLeft: 5,
                paddingRight: 5,
                overflowY: "auto",
              }}
              onChange={(e) => setCandlestickChartField(e)}
              defaultValue={candlestickChartField}
              virtual={false}
            >
              <Option className="option" key={""} value={""} />
              {["Πωλήσεις", "Αγορές"].map((item) => (
                <Option className="option" key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <Chart
            chartType="CandlestickChart"
            width="95%"
            height={!chartParamMenuVisible ? "60vh" : "55vh"}
            data={data}
            options={chartOptions}
          />
        </Flex>
      </Flex>
    );
  }

  if (
    !["PieChart", "CandleStickChart"].includes(chartType) &&
    data?.[0]?.length
  ) {
    return (
      <Flex
        key={width}
        flexDirection={"column"}
        style={{
          width: "100%",
          alignItems: "center",
          height: "60vh",
          overflowY: "auto",
        }}
      >
        <Flex
          flexDirection={"row"}
          style={{
            width: "95%",
            alignItems: "center",
            height: "max-content",
          }}
        >
          <Chart
            chartType={finalChartType}
            width="95%"
            height={"60vh"}
            data={data}
            options={chartOptions}
          />

          {chartParamMenuVisible && (
            <ChartSettingsDisplayer
              chartParamMenuVisible={chartParamMenuVisible}
              changeChartParamMenuVisible={changeChartParamMenuVisible}
            />
          )}
        </Flex>
        <ChartTable data={data} columns={columns} />
      </Flex>
    );
  }

  return <div />;
};

export default ChartDisplayer;
