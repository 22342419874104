import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { useChartStore } from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";

const Radial = ({ data }) => {
  const color = useChartStore((state) => state.color);
  const innerRadius = useChartStore((state) => state.innerRadius);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const colorSet20 = useChartStore((state) => state.colorSet20);
  const colorSet40 = useChartStore((state) => state.colorSet40);
  const radialDisplay = useChartStore((state) => state.radialDisplay);

  //console.log("RadialChart: data: ", data);

  const numOfLegends = Object.keys(data?.[0]?.data ?? [])?.length;

  //console.log("data: ", data);

  //const dataForLegends = [];

  let dataForLegends = [];
  if (data?.[0]?.data?.length) {
    dataForLegends = data[0].data.map((item) => item.x);
  }

  //console.log("dataForLegends: ", dataForLegends);

  //  const numOfLegends = Object.keys(dataForLegends[0]).length;

  let chartColor = colorSet20;
  if (numOfLegends > 20) {
    chartColor = [...colorSet20, ...colorSet40];
  }

  const dataForLegendsPart1 = [];
  const dataForLegendsPart2 = [];
  if (numOfLegends <= 20) {
    dataForLegendsPart1.push(...dataForLegends);
  } else {
    for (let i = 0; i < dataForLegends.length; i++) {
      if (i <= numOfLegends / 2 - 1) {
        dataForLegendsPart1.push(dataForLegends[i]);
      } else {
        dataForLegendsPart2.push(dataForLegends[i]);
      }
    }
  }

  // console.log("dataForLegendsPart1: ", dataForLegendsPart1);
  // console.log("dataForLegendsPart2: ", dataForLegendsPart2);

  let max = 10;
  for (let i = 0; i < data?.length; i++) {
    let tempMax = 0;
    for (let j = 0; j < data[i]?.data?.length ?? 0; j++) {
      tempMax += data[i].data[j].y;
    }
    if (tempMax > max) {
      max = tempMax;
    }
  }

  return (
    <Flex
      key={radialDisplay}
      flexDirection={"row"}
      style={{
        width: "95%",
        alignItems: "center",
        height: "55vh",
      }}
    >
      <ResponsiveRadialBar
        key={radialDisplay}
        data={data}
        maxValue={displayFunction === "pctCount" ? 1 : max > 10 ? max : 10}
        //colors={numOfLegends > 10 ? chartColorPalette : { scheme: color }}
        colors={chartColor}
        innerRadius={innerRadius}
        valueFormat={displayFunction === "pctCount" ? ">-.2p" : ">-.0f"}
        enableLabels={true}
        labelsTextColor={"white"}
        padding={0.2}
        theme={{
          axis: {
            legend: {
              text: {
                fill: "rgb(8, 8, 46)",
              },
            },
            ticks: {
              text: {
                fill: "rgb(8, 8, 46)",
              },
            },
          },
        }}
        cornerRadius={2}
        margin={{ top: 30, right: 220, bottom: 70, left: 40 }}
        radialAxisStart={{ tickSize: 5, tickPadding: 10, tickRotation: 0 }}
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        // legends={[
        //   {
        //     anchor: "right",
        //     direction: "column",
        //     justify: false,
        //     translateX: 80,
        //     translateY: 0,
        //     itemsSpacing: 3,
        //     itemDirection: "left-to-right",
        //     itemWidth: 50,
        //     itemHeight: 12,
        //     itemTextColor: "rgb(8, 8, 46)",
        //     symbolSize: 12,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
      <Flex
        flexDirection={"column"}
        style={{ marginLeft: "-20rem", marginTop: "4rem" }}
      >
        {dataForLegendsPart1.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfLegends < 15 ? 4 : 2,
            }}
          >
            <div
              style={{
                width: numOfLegends < 15 ? 13 : 10,
                maxHeight: numOfLegends < 15 ? 12 : 9,
                borderRadius: 15,
                background: colorSet20[index],
              }}
            />
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
      <Flex
        flexDirection={"column"}
        style={{ marginLeft: "1rem", marginTop: "4rem" }}
      >
        {dataForLegendsPart2.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfLegends < 15 ? 4 : 2,
            }}
          >
            <div
              style={{
                width: numOfLegends < 15 ? 13 : 10,
                maxHeight: numOfLegends < 15 ? 12 : 9,
                borderRadius: 15,
                background: colorSet40[index],
              }}
            />
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Radial;
