import Flex from "../../../helpers/components/Flex";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import {
  LeftOutlined,
  RightOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";

const HideShowConfigurator = ({
  showConfigurator,
  setShowConfigurator,
  chartVisible,
}) => {
  const { width } = useWindowDimensions();

  if (!chartVisible) {
    return <Flex />;
  }

  if (width < 1524 && showConfigurator) {
    return (
      <LeftOutlined
        style={{ fontSize: 16 }}
        onClick={() => setShowConfigurator(false)}
      />
    );
  }

  if (width < 1524 && !showConfigurator) {
    return (
      <RightOutlined
        style={{ fontSize: 16 }}
        onClick={() => setShowConfigurator(true)}
      />
    );
  }

  if (width > 1524 && showConfigurator) {
    return (
      <UpOutlined
        style={{ fontSize: 16 }}
        onClick={() => setShowConfigurator(false)}
      />
    );
  }

  if (width > 1524 && !showConfigurator) {
    return (
      <DownOutlined
        style={{ fontSize: 16 }}
        onClick={() => setShowConfigurator(true)}
      />
    );
  }
};

export default HideShowConfigurator;
