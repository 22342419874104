import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import "./css/ultimateTable.css";
import InfoCard from "./infoCard/InfoCard";
import { MinusCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Checkbox, Tooltip } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import tableDisplayDecider from "./helpers/tableDisplayDecider/tableDisplayDecider";
import useCollapse from "./hooks/useCollapse";
import { useEffect, useState } from "react";
import { useCommonTitlesStore, useAuthStore } from "../../store/useStores";
import htmlCodesRemover from "./helpers/htmlCodesRemover";
import Flex from "../../helpers/components/Flex";
import infoDisplayDecider from "./helpers/infoDisplayDecider/infoDisplayDecider";
import UltimateTableTotals from "./UltimateTableTotals";
//import chooseTableCellStyle from "./helpers/chooseTableCellStyle";

const UltimateTableBody = ({
  title,
  columns,
  staticOptions,
  foreignKeyValues,
  privileges,
  config,
  dataToUse,
  originalData,
  grouping,
  selection,
  isSelected,
  showGroup,
  selectIndividualRow,
  updateRow,
  deleteRow,
  getColumnID,
  dateFormatToUse,
  totalRowWidth,
  moduleID,
  insertData,
  autoCompletedFields,
  updateTotals,
  allDataToUse,
  totals = {},
  showTotals,
  dense,
  //frozenColumns = [],
}) => {
  const importantColumns = columns.filter(({ F_LIST }) => F_LIST === "Y") ?? [];
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const role = useAuthStore((state) => state.role);

  const tooltipTypes = ["1", "27"];

  const {
    isOpen: isGroupOpen,
    invert: invertOpenGroupDetails,
    forceOpen: forceOpenGroupDetails,
  } = useCollapse();

  useEffect(() => {
    if (showGroup) {
      const temp = grouping.map((item) => item.label);
      forceOpenGroupDetails(temp);
    } else {
      forceOpenGroupDetails([]);
    }
  }, [showGroup, dataToUse]);

  const [dataInGroup, setDataInGroup] = useState({});

  useEffect(() => {
    if (showGroup) {
      let tempDataInGroup = {};
      grouping.forEach(({ id, label, dateInfo }) => {
        const groupMonth = dateInfo?.month ?? null;
        const groupYear = dateInfo?.year ?? null;

        let progressColumns = [];
        let staticListColumns = [];
        let checkboxColumns = [];
        let alertColumns = [];

        columns.forEach((column) => {
          if (column.F_RENDER === "PROGRESS") {
            progressColumns.push(column.F_ID);
          }
          if (column.F_TYPE === "6" && column.F_RENDER !== "PROGRESS") {
            staticListColumns.push(column.F_ID);
          }
          if (column.F_TYPE === "99") {
            checkboxColumns.push(column.F_ID);
          }
          if (column.F_RENDER === "ALERT") {
            alertColumns.push(column.F_ID);
          }
        });

        let labelData = [];
        if (
          showGroup.length > 4 &&
          !["status", "type", "module"].includes(showGroup)
        ) {
          if (label !== "-") {
            labelData = allDataToUse.filter((row) => {
              const F_ID = getColumnID(showGroup);
              const date = row[`${F_ID}-date`];

              const month = date.getMonth();
              const year = date.getFullYear();

              return month === groupMonth && year === groupYear;
            });
          } else {
            const F_ID = getColumnID(showGroup);
            labelData = allDataToUse.filter(
              (row) =>
                row[F_ID] === null ||
                row[F_ID] === undefined ||
                row[F_ID] === ""
            );
          }
        } else if (progressColumns.includes(showGroup)) {
          if (id !== "-") {
            labelData = allDataToUse.filter(
              (row) => row[showGroup]?.view === id
            );
          } else {
            labelData = allDataToUse.filter(
              (row) =>
                row[showGroup] === null ||
                row[showGroup] === undefined ||
                row[showGroup] === ""
            );
          }
        } else if (staticListColumns.includes(showGroup)) {
          if (showGroup === "status") {
            labelData = allDataToUse.filter(
              (row) => row[showGroup].view === id.view
            );
          } else {
            if (id !== "-") {
              labelData = allDataToUse.filter(
                (row) => row[showGroup]?.view === id
              );
            } else {
              labelData = allDataToUse.filter(
                (row) =>
                  row[showGroup] === null ||
                  row[showGroup] === undefined ||
                  row[showGroup] === ""
              );
            }
          }
        } else if (checkboxColumns.includes(showGroup)) {
          labelData = allDataToUse.filter((row) => {
            if (id === "N") {
              if (!row[showGroup]) {
                return true;
              } else if (row[showGroup] === "N") {
                return true;
              } else return false;
            } else {
              return row[showGroup] === id;
            }
          });
        } else if (alertColumns.includes(showGroup)) {
          if (id !== "-") {
            labelData = allDataToUse.filter(
              (row) => row[showGroup]?.value === id
            );
          } else {
            labelData = allDataToUse.filter(
              (row) =>
                row?.[showGroup]?.value === null ||
                row?.[showGroup]?.value === undefined ||
                row?.[showGroup]?.value === ""
            );
          }
        } else {
          if (id !== "-") {
            labelData = allDataToUse.filter((row) => row[showGroup] === id);
          } else {
            labelData = allDataToUse.filter(
              (row) =>
                row[showGroup] === null ||
                row[showGroup] === undefined ||
                row[showGroup] === ""
            );
          }
        }
        tempDataInGroup = { ...tempDataInGroup, [label]: labelData };
      });
      setDataInGroup(tempDataInGroup);
    }
  }, [showGroup]);

  const { isOpen, invert: invertOpenDetails } = useCollapse();

  const rows = (row) => {
    const InvertIcon = isOpen(row.rowID) ? MinusCircleOutlined : EyeOutlined;

    let viewRights = {};
    columns.forEach((column) => {
      if (role === "A") {
        viewRights = { ...viewRights, [column.F_ID]: true };
      } else if (Object.keys(column).includes("canview")) {
        viewRights = {
          ...viewRights,
          [column.F_ID]: column.canview,
        };
      } else {
        viewRights = {
          ...viewRights,
          [column.F_ID]: privileges?.canView,
        };
      }
    });

    return (
      <>
        {selection && (
          <TableCell
            key={`${row.rowID}-checkbox`}
            padding="checkbox"
            width={15}
            style={
              isOpen(row.rowID)
                ? {
                    verticalAlign: "top",
                    paddingTop: 15,
                    paddingLeft: 15,
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 3,
                  }
                : {
                    paddingLeft: 15,
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 3,
                  }
            }
          >
            <Checkbox
              checked={isSelected(row.rowID)}
              onChange={() => selectIndividualRow(row.rowID)}
            />
          </TableCell>
        )}
        <TableCell
          key={`${row.rowID}-invert`}
          width={35}
          style={{
            position: "sticky",
            left: selection ? 48 : 0,
            background: "white",
            zIndex: 3,
            display: isOpen(row.rowID) && "none",
            padding: 0,
            justifyContent: "center",
          }}
        >
          <Tooltip
            placement="right"
            title={
              !isOpen(row.rowID)
                ? commonTitles["CO|1|SHOWINFO"]
                : commonTitles["CO|1|HIDEINFO"]
            }
          >
            <InvertIcon
              onClick={() => invertOpenDetails(row.rowID)}
              style={{
                fontSize: "18px",
                color: "rgb(8, 8, 46)",
                backgroundColor: "white",
                zIndex: 3,
                margin: 8,
              }}
            />
          </Tooltip>
        </TableCell>
        {isOpen(row.rowID) ? (
          <InfoCard
            key={`${row.rowID}-infobox`}
            title={title}
            rowID={row.rowID}
            columns={columns}
            row={row}
            staticOptions={staticOptions}
            foreignKeyValues={foreignKeyValues}
            length={importantColumns.length}
            updateRow={updateRow}
            deleteRow={deleteRow}
            originalData={originalData}
            dataToUse={dataToUse}
            moduleID={moduleID}
            config={config}
            insertData={insertData}
            privileges={privileges}
            autoCompletedFields={autoCompletedFields}
            updateTotals={updateTotals}
            isOpen={isOpen}
            invertOpenDetails={invertOpenDetails}
            InvertIcon={InvertIcon}
          />
        ) : (
          <>
            {config === "alerts" ? (
              <>
                {columns.map(
                  ({
                    F_ID,
                    F_RENDER,
                    F_TYPE,
                    F_WIDTH,
                    F_ALIGN,
                    T_TITLE,
                    F_LIST,
                  }) =>
                    F_LIST && (
                      <Tooltip
                        key={`${row.rowID} - ${F_ID}`}
                        placement="bottom"
                        color="white"
                        overlayInnerStyle={{ color: "black" }}
                        title={
                          tooltipTypes.includes(F_TYPE) &&
                          htmlCodesRemover(row[F_ID])?.length > 50
                            ? htmlCodesRemover(row[F_ID])
                            : null
                        }
                      >
                        <TableCell
                          key={`${row.rowID} - ${F_ID}`}
                          width={`${(100 * F_WIDTH) / totalRowWidth}%`}
                          align={F_ALIGN.toLowerCase()}
                          style={
                            F_TYPE === "6"
                              ? { backgroundColor: row[F_ID]?.bgColor }
                              : null
                          }
                        >
                          {tableDisplayDecider(
                            F_RENDER,
                            F_TYPE,
                            row[F_ID],
                            config,
                            null,
                            null,
                            F_ID
                          )}
                        </TableCell>
                      </Tooltip>
                    )
                )}
              </>
            ) : (
              <>
                {columns
                  .filter((column) => viewRights[column.F_ID])
                  .map(
                    (item) =>
                      item.F_LIST && (
                        <Tooltip
                          key={`${row.rowID} - ${item.F_ID}`}
                          placement="left"
                          color="white"
                          overlayInnerStyle={{ color: "black" }}
                          title={
                            ["24", "25"].includes(item?.F_TYPE) &&
                            row?.[item?.F_ID]?.length
                              ? infoDisplayDecider(
                                  item?.F_RENDER,
                                  item?.F_TYPE,
                                  row[item?.F_ID],
                                  config,
                                  columns,
                                  row,
                                  row[`${item?.F_ID}-server`] ?? null,
                                  foreignKeyValues,
                                  item?.F_ID,
                                  false,
                                  item?.T_TITLE,
                                  item?.F_PARENTFIELDID,
                                  item?.REFMODULE,
                                  item?.REFFIELD
                                )
                              : tooltipTypes.includes(item.F_TYPE)
                              ? htmlCodesRemover(row[item.F_ID])
                              : null
                          }
                        >
                          <TableCell
                            key={`${row.rowID} - ${item.F_ID}`}
                            width={`${(100 * item.F_WIDTH) / totalRowWidth}%`}
                            align={item.F_ALIGN.toLowerCase()}
                            style={
                              item.F_TYPE === "6"
                                ? { backgroundColor: row[item.F_ID]?.bgColor }
                                : item.F_RENDER === "ALERT" &&
                                  row[item.F_ID]?.color?.[1] === "B"
                                ? {
                                    backgroundColor: row[item.F_ID]?.color?.[0],
                                  }
                                : null
                              // chooseTableCellStyle(
                              //   item.F_ID,
                              //   frozenColumns,
                              //   item.F_TYPE === "6"
                              //     ? row[item.F_ID]?.bgColor
                              //     : item.F_RENDER === "ALERT" &&
                              //       row[item.F_ID]?.color?.[1] === "B"
                              //     ? row[item.F_ID]?.color?.[0]
                              //     : null,
                              //   selection,
                              //   columns
                              // )
                              // item.F_TYPE === "6"
                              //   ? {
                              //       backgroundColor: row[item.F_ID]?.bgColor,
                              //       position: index === 0 && "sticky",
                              //       left:
                              //         index === 0 && selection
                              //           ? 80
                              //           : index === 0
                              //           ? 32
                              //           : null,
                              //       zIndex: index === 0 && 3,
                              //     }
                              //   : item.F_RENDER === "ALERT" &&
                              //     row[item.F_ID]?.color?.[1] === "B"
                              //   ? {
                              //       backgroundColor: row[item.F_ID]?.color?.[0],
                              //       position: index === 0 && "sticky",
                              //       left:
                              //         index === 0 && selection
                              //           ? 80
                              //           : index === 0
                              //           ? 32
                              //           : null,
                              //       zIndex: index === 0 && 3,
                              //       backgroundColor: "white",
                              //     }
                              //   : {
                              //       position: index === 0 && "sticky",
                              //       left:
                              //         index === 0 && selection
                              //           ? 80
                              //           : index === 0
                              //           ? 32
                              //           : null,
                              //       zIndex: index === 0 && 3,
                              //       backgroundColor: "white",
                              //     }
                            }
                          >
                            {tableDisplayDecider(
                              item?.F_RENDER,
                              item?.F_TYPE,
                              row[item.F_ID],
                              config,
                              row[`${item.F_ID}-server`] ?? null,
                              foreignKeyValues,
                              item.F_ID,
                              row,
                              item?.T_TITLE,
                              item?.F_PARENTFIELDID,
                              item?.REFMODULE,
                              item?.REFFIELD,
                              item?.FK_FIELDS ?? []
                            )}
                          </TableCell>
                        </Tooltip>
                      )
                  )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const ShowGroupCollapsed = ({ id, label, dateInfo }) => {
    const InvertIcon = isGroupOpen(label) ? DownOutlined : RightOutlined;

    // const groupMonth = dateInfo?.month ?? null;
    // const groupYear = dateInfo?.year ?? null;

    // let progressColumns = [];
    // let staticListColumns = [];
    // let checkboxColumns = [];
    // let alertColumns = [];

    // columns.forEach((column) => {
    //   if (column.F_RENDER === "PROGRESS") {
    //     progressColumns.push(column.F_ID);
    //   }
    //   if (column.F_TYPE === "6" && column.F_RENDER !== "PROGRESS") {
    //     staticListColumns.push(column.F_ID);
    //   }
    //   if (column.F_TYPE === "99") {
    //     checkboxColumns.push(column.F_ID);
    //   }
    //   if (column.F_RENDER === "ALERT") {
    //     alertColumns.push(column.F_ID);
    //   }
    // });

    // let dataInGroup = [];
    // if (
    //   showGroup.length > 4 &&
    //   !["status", "type", "module"].includes(showGroup)
    // ) {
    //   if (label !== "-") {
    //     dataInGroup = allDataToUse.filter((row) => {
    //       const F_ID = getColumnID(showGroup);
    //       const date = row[`${F_ID}-date`];

    //       const month = date.getMonth();
    //       const year = date.getFullYear();

    //       return month === groupMonth && year === groupYear;
    //     });
    //   } else {
    //     const F_ID = getColumnID(showGroup);
    //     dataInGroup = allDataToUse.filter(
    //       (row) =>
    //         row[F_ID] === null || row[F_ID] === undefined || row[F_ID] === ""
    //     );
    //   }
    // } else if (progressColumns.includes(showGroup)) {
    //   if (id !== "-") {
    //     dataInGroup = allDataToUse.filter((row) => row[showGroup]?.view === id);
    //   } else {
    //     dataInGroup = allDataToUse.filter(
    //       (row) =>
    //         row[showGroup] === null ||
    //         row[showGroup] === undefined ||
    //         row[showGroup] === ""
    //     );
    //   }
    // } else if (staticListColumns.includes(showGroup)) {
    //   if (showGroup === "status") {
    //     dataInGroup = allDataToUse.filter(
    //       (row) => row[showGroup].view === id.view
    //     );
    //   } else {
    //     if (id !== "-") {
    //       dataInGroup = allDataToUse.filter(
    //         (row) => row[showGroup]?.view === id
    //       );
    //     } else {
    //       dataInGroup = allDataToUse.filter(
    //         (row) =>
    //           row[showGroup] === null ||
    //           row[showGroup] === undefined ||
    //           row[showGroup] === ""
    //       );
    //     }
    //   }
    // } else if (checkboxColumns.includes(showGroup)) {
    //   dataInGroup = allDataToUse.filter((row) => {
    //     if (id === "N") {
    //       if (!row[showGroup]) {
    //         return true;
    //       } else if (row[showGroup] === "N") {
    //         return true;
    //       } else return false;
    //     } else {
    //       return row[showGroup] === id;
    //     }
    //   });
    // } else if (alertColumns.includes(showGroup)) {
    //   if (id !== "-") {
    //     dataInGroup = allDataToUse.filter(
    //       (row) => row[showGroup]?.value === id
    //     );
    //   } else {
    //     dataInGroup = allDataToUse.filter(
    //       (row) =>
    //         row?.[showGroup]?.value === null ||
    //         row?.[showGroup]?.value === undefined ||
    //         row?.[showGroup]?.value === ""
    //     );
    //   }
    // } else {
    //   if (id !== "-") {
    //     dataInGroup = allDataToUse.filter((row) => row[showGroup] === id);
    //   } else {
    //     dataInGroup = allDataToUse.filter(
    //       (row) =>
    //         row[showGroup] === null ||
    //         row[showGroup] === undefined ||
    //         row[showGroup] === ""
    //     );
    //   }
    // }

    // console.log("label: ", label);

    return (
      <>
        <TableRow key={label}>
          <TableCell
            colSpan={importantColumns.length + 2}
            className="grouping-row"
            align="left"
            onClick={() => invertOpenGroupDetails(label)}
          >
            <Flex flexDirection={"row"}>
              <InvertIcon
                style={{
                  fontSize: "12px",
                  marginRight: "1rem",
                  color: "rgb(8, 8, 46)",
                }}
              />
              <p>
                {label ? label : "-"} ({dataInGroup?.[label]?.length ?? 0})
              </p>
            </Flex>
          </TableCell>
        </TableRow>

        {isGroupOpen(label) &&
          dataInGroup?.[label].map((row, index) => (
            <TableRow key={`${row.rowID}-${index}`}>{rows(row)}</TableRow>
          ))}
      </>
    );
  };

  const ShowGroupUncollapsed = ({ value }) => {
    return (
      <>
        <TableRow key={value}>
          <TableCell
            colSpan={importantColumns.length + 2}
            className="grouping-row"
            align="left"
            onClick={() => invertOpenGroupDetails(value)}
          >
            <RightOutlined
              style={{
                fontSize: "12px",
                marginRight: "1rem",
                color: "rgb(8, 8, 46)",
              }}
            />
            {value}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const ShowGroup = () => {
    return grouping.map(({ id, label, dateInfo }) => {
      // if (isGroupOpen(label)) {
      return (
        <ShowGroupCollapsed
          key={`${id} - ${label}`}
          id={id}
          label={label}
          dateInfo={dateInfo}
        />
      );
      // } else {
      //   return <ShowGroupUncollapsed key={`${id} - ${label}`} value={label} />;
      // }
    });
  };

  const RawData = () => {
    return (
      <>
        {dataToUse.map((row, index) => (
          <TableRow key={`${row.rowID}-${index}`}>{rows(row)}</TableRow>
        ))}
      </>
    );
  };

  return (
    <TableBody>
      {showGroup ? <ShowGroup /> : <RawData />}
      {showTotals && (
        <UltimateTableTotals
          totals={totals}
          columns={columns}
          privileges={privileges}
          totalRowWidth={totalRowWidth}
          selection={selection}
          dense={dense}
          //frozenColumns={frozenColumns}
        />
      )}
    </TableBody>
  );
};

export default UltimateTableBody;
