import { Layout } from "antd";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Modal, Button } from "antd";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";

const { Content } = Layout;

const ChartTable = ({ data }) => {
  const colorSet40 = useChartStore((state) => state.colorSet40);

  const headers = data?.[0].filter((item, index) => index !== 0) ?? [];

  const bodyData = [
    { id: "2020", Sales: 150, Buys: 182 },
    { id: "2021", Sales: 135, Buys: 120 },
    { id: "2022", Sales: 300, Buys: 200 },
  ];

  const data2 = [
    ["Year", "Sales", "Buys"],
    ["2020", 150, 182],
    ["2021", 135, 120],
    ["2022", 300, 220],
  ];

  return (
    <Layout
      style={{
        borderRadius: 10,
        // overflow: "hidden",
        height: "max-content",
        width: "100%",
        marginTop: 80,
        marginBottom: 80,
      }}
    >
      <Content
        style={{
          padding: 10,
          minHeight: "max-content",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TableContainer component={Paper} className="testing_table">
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {data2[0].map((header, index) => {
                  if (index === 0) {
                    return (
                      <TableCell
                        key={`${index}-${header}`}
                        style={{ backgroundColor: "#f0f2f5" }}
                      />
                    );
                  } else {
                    return (
                      <TableCell
                        key={`${index}-${header}`}
                        align="center"
                        style={{
                          backgroundColor:
                            colorSet40[index - 1] ?? "rgb(206, 223, 252)",
                          color: "white",
                          fontSize: 13,
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {header}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {bodyData.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    {Object.values(item).map((value, index) => {
                      if (index === 0) {
                        return (
                          <TableCell
                            key={value}
                            align="center"
                            style={{
                              backgroundColor: "rgb(206, 223, 252)",
                              fontWeight: 600,
                              width: "8%",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      } else
                        return (
                          <TableCell key={`${index}-${value}`} align="center">
                            {value ? Math.round(value * 100) / 100 : null}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </Layout>
  );
};

export default ChartTable;
