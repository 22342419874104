import { useChartStore } from "../../../store/useStores";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import { Tooltip } from "antd";
import Icon from "@ant-design/icons";

const CandlestickChartButton = () => {
  const { width } = useWindowDimensions();
  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);

  const CandlestickChartSVG = () => (
    <svg width="30" height="30" fill="currentColor" viewBox="0 0 400 400">
      <g>
        <path
          d="M410.916,375.428v22.415H0V13.072h22.413v362.355H410.916z M89.193,315.652h11.208v-50.431h10.27V145.689h-10.27V93.393
     H89.193v52.296H78.917v119.533h10.277V315.652z M152.69,241.872h11.207v-51.365h10.276V70.971h-10.276V19.606H152.69v51.365h-10.27
     v119.536h10.27V241.872z M215.727,279.229h11.207v-49.488h10.271V110.194h-10.271V56.963h-11.207v53.231h-10.276V229.73h10.276
     V279.229z M287.169,300.243h11.21v-49.965h10.273V130.742h-10.273V77.976h-11.21v52.767h-10.269v119.536h10.269V300.243z
      M360.484,242.349h11.206v-51.833h10.271V70.971H371.69V20.077h-11.206v50.895h-10.276v119.536h10.276V242.349z"
        />
      </g>
    </svg>
  );

  const CandlestickIcon = (props) => (
    <Icon component={CandlestickChartSVG} {...props} />
  );

  return (
    <Tooltip
      placement={width < 1525 ? "bottom" : "right"}
      title={"Candlestick chart"}
    >
      <CandlestickIcon
        style={{
          backgroundColor:
            chartType === "CandlestickChart" && "rgb(84, 190, 243)",
          color: chartType === "CandlestickChart" ? "#fff" : "rgb(8, 8, 46)",
          width: "max-content",
          padding: 8,
          fontSize: "30px",
        }}
        onClick={() => setChartType("CandlestickChart")}
      />
      {/* <CandlestickChartIcon
        style={{
          backgroundColor: chartType === "LineChart" && "rgb(84, 190, 243)",
          color: chartType === "LineChart" ? "#fff" : "rgb(8, 8, 46)",
          width: "max-content",
          padding: 8,
          fontSize: "30px",
        }}
        onClick={() => setChartType("LineChart")}
      /> */}
    </Tooltip>
  );
};

export default CandlestickChartButton;
