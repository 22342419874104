import Flex from "../../../../../helpers/components/Flex";
import { Select } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useAuthStore } from "../../../../../store/useStores";

const { Option } = Select;

const MultipleSelectionType = ({
  id,
  config,
  required,
  addChange,
  canupdate,
  canupdateifnull,
  foreignKeyValues,
  row,
  autoCompletedFields = {},
}) => {
  const userID = useAuthStore((state) => state.userID);

  let activeValue =
    config === "filters"
      ? row?.[id] ?? []
      : row?.[`${id}-server`]?.split(",").map((item) => item) ?? [];
  let options = [];
  if (foreignKeyValues[id]) {
    options = Object.entries(foreignKeyValues[id]).map(([key, value]) => ({
      id: key,
      view: value.view,
    }));
    options.sort((a, b) => a.view.localeCompare(b.view));
  }

  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    activeValue = autoCompletedFields[id];
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (activeValue.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  return (
    <Flex flexDirection={"row"}>
      <div className="select">
        <Select
          mode="multiple"
          allowClear
          style={{
            width: 278,
            marginLeft: 7,
            marginTop: 4,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            const replacements = [
              { key: "ί", newKey: "ι" },
              { key: "έ", newKey: "ε" },
              { key: "ό", newKey: "ο" },
              { key: "ή", newKey: "η" },
              { key: "ύ", newKey: "υ" },
              { key: "ώ", newKey: "ω" },
              { key: "ά", newKey: "α" },
              { key: "ς", newKey: "σ" },
            ];
            let opToUse = op.children ? op.children.toLowerCase() : "";
            let ivToUse = iv.toLowerCase();
            replacements.forEach(({ key, newKey }) => {
              var re = new RegExp(key, "g");
              ivToUse = ivToUse.replace(re, newKey);
              opToUse = opToUse.replace(re, newKey);
            });
            return opToUse.includes(ivToUse);
          }}
          onChange={(e) => addChange(id, e, null)}
          defaultValue={activeValue ?? []}
          disabled={isDisabled}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          {config !== "filters" && <Option value={[]} />}
          {options.map((item) => (
            <Option key={item?.view} className="option" value={item?.id}>
              {item?.view}
            </Option>
          ))}
        </Select>
      </div>
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default MultipleSelectionType;
