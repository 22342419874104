import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./data.css";
import DnsIcon from "@mui/icons-material/Dns";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";

const Data = ({ path }) => {
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Link to={`${path}`} className="metrics" data-tip={`${commonTitles.data}`}>
      {<DnsIcon style={{ color: bannerTextColor }} />}
      <ReactTooltip place="bottom" />
    </Link>
  );
  // switch (title) {
  //   case "Action Track Register":
  //     return (
  //       <Link
  //         to={`/${path}`}
  //         className="data"
  //         data-tip={`${commonTitles.data}`}
  //       >
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   case "Safe Pass":
  //     return (
  //       <Link
  //         to="/safe_pass"
  //         className="data"
  //         data-tip={`${commonTitles.data}`}
  //       >
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   case "Training Program":
  //     return (
  //       <Link
  //         to="/training_program"
  //         className="data"
  //         data-tip={`${commonTitles.data}`}
  //       >
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   case "Employees":
  //     return (
  //       <Link
  //         to="/employees"
  //         className="data"
  //         data-tip={`${commonTitles.data}`}
  //       >
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   case "Entities":
  //     return (
  //       <Link
  //         to="/entities_metrics"
  //         className="data"
  //         data-tip={`${commonTitles.data}`}
  //       >
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   case "People":
  //     return (
  //       <Link to="/people" className="data" data-tip={`${commonTitles.data}`}>
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   case "Issues":
  //     return (
  //       <Link to="/issues" className="data" data-tip={`${commonTitles.data}`}>
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  //   default:
  //     return (
  //       <Link className="data" data-tip={`${commonTitles.data}`}>
  //         {<DnsIcon className="data__icon" />}
  //         <ReactTooltip place="bottom" />
  //       </Link>
  //     );
  // }
};

export default Data;
