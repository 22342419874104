import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "./banner/Banner";
import Navigation from "../navigation/Navigation";
import {
  useFilterStore,
  useMenuStore,
  useAuthStore,
  useChartStore,
  useCommonTitlesStore,
  useModuleStore,
} from "../../store/useStores";
import apiGetData from "../../api/endpoints/apiGetData";
import createFilters from "./UCB helpers/createFilters";
import Flex from "../../helpers/components/Flex";
import { Card } from "antd";
import htmlCodesRemover from "../ultimateTable/helpers/htmlCodesRemover";
import useWindowDimensions from "../ultimateTable/hooks/useWindowDimensions";
import apiGetMetrics from "../../api/endpoints/apiGetMetrics";
import getChartOptions from "./getChartOptions";
import Loader from "./loader/Loader";
import SelectChartButtons from "./selectChartButtons/SelectChartButtons";
import getChartData from "./getChartData";
import ChartDisplayer from "./chartDisplayer/ChartDisplayer";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";
import ModuleWrapper from "../module/wrappers/ModuleWrapper";
import ChartConfigurator from "./chartConfigurator/ChartConfigurator";
import createOptions from "./UCB helpers/createOptions";

const UltimateChartBackend = () => {
  const { moduleID } = useParams();
  const baseURL = useAuthStore((state) => state.baseURL);
  const role = useAuthStore((state) => state.role);
  const menu = useMenuStore((state) => state.menu);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [foreignKeyValues, setForeignKeyValues] = useState({});
  const [staticOptions, setStaticOptions] = useState({});

  const [chartOptions, setChartOptions] = useState({});
  const [filterStatus, setFilterStatus] = useState(false);
  const [groupFieldStatus, setGroupFieldStatus] = useState(false);

  const filters = useFilterStore((state) => state.filters);
  const setFilters = useFilterStore((state) => state.setFilters);

  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);
  const grouping = useChartStore((state) => state.grouping);
  const setGrouping = useChartStore((state) => state.setGrouping);
  const groupField = useChartStore((state) => state.groupField);
  const setGroupField = useChartStore((state) => state.setGroupField);
  const xAxisField = useChartStore((state) => state.xAxisField);
  const setXAxisField = useChartStore((state) => state.setXAxisField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);
  const sumField = useChartStore((state) => state.sumField);
  const setSumField = useChartStore((state) => state.setSumField);
  const setChartBase64 = useChartStore((state) => state.setChartBase64);
  const colorSet40 = useChartStore((state) => state.colorSet40);

  const groupMode = useChartStore((state) => state.groupMode);
  const setGroupMode = useChartStore((state) => state.setGroupMode);
  const setStartAngle = useChartStore((state) => state.setStartAngle);
  const setEndAngle = useChartStore((state) => state.setEndAngle);
  const layout = useChartStore((state) => state.layout);
  const setLayout = useChartStore((state) => state.setLayout);
  const setColor = useChartStore((state) => state.setColor);
  const setLineGroupMode = useChartStore((state) => state.setLineGroupMode);
  const interpolation = useChartStore((state) => state.interpolation);
  const setInterpolation = useChartStore((state) => state.setInterpolation);
  const innerRadius = useChartStore((state) => state.innerRadius);
  const setInnerRadius = useChartStore((state) => state.setInnerRadius);
  const showAverage = useChartStore((state) => state.showAverage);
  const setShowAverage = useChartStore((state) => state.setShowAverage);
  const is3D = useChartStore((state) => state.is3D);
  const setIs3D = useChartStore((state) => state.setIs3D);
  const radialDisplay = useChartStore((state) => state.radialDisplay);
  const showTrendline = useChartStore((state) => state.showTrendline);
  const setShowTrendline = useChartStore((state) => state.setShowTrendline);
  const trendline = useChartStore((state) => state.trendline);
  const setTrendline = useChartStore((state) => state.setTrendline);
  const areaDisplay = useChartStore((state) => state.areaDisplay);

  const groupFieldOptions = useChartStore((state) => state.groupFieldOptions);
  const setGroupFieldOptions = useChartStore(
    (state) => state.setGroupFieldOptions
  );
  const dateFieldOptions = useChartStore((state) => state.dateFieldOptions);
  const setDateFieldOptions = useChartStore(
    (state) => state.setDateFieldOptions
  );
  const sumFieldOptions = useChartStore((state) => state.sumFieldOptions);
  const setSumFieldOptions = useChartStore((state) => state.setSumFieldOptions);

  const [finalChartType, setFinalChartType] = useState("");

  const [chartVisible, setChartVisible] = useState(false);
  const [reportIconVisible, setReportIconVisible] = useState(false);

  const [paramMenu, setParamMenu] = useState([]);
  const [paramMenuTitle, setParamMenuTitle] = useState("");
  const [privileges, setPrivileges] = useState({});
  const [moduleParams, setModuleParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chartParamMenuVisible, setChartParamMenuVisible] = useState(false);
  const { width } = useWindowDimensions();

  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  const [eraseButton, setEraseButton] = useState(false);
  const [pieChartXField, setPieChartXField] = useState("2020");
  const [candlestickChartField, setCandlestickChartField] =
    useState("Πωλήσεις");

  useEffect(async () => {
    setIsLoading(true);
    const {
      fields,
      staticoptions,
      fkvalues,
      filter,
      menu: parametricMenu,
      canview,
      candelete,
      caninsert,
      canupdate,
    } = await apiGetData(
      moduleID,
      baseURL,
      filters[moduleID] ? filters[moduleID] : {}
    );

    let tempColumns = [];
    tempColumns =
      fields
        .filter((field) => field.F_LIST === "Y")
        .map((field) => ({
          ...field,
          T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
        })) ?? [];
    setColumns(tempColumns);
    setForeignKeyValues(fkvalues);
    setStaticOptions(staticoptions);
    setFilters({ [moduleID]: { ...filter } });
    setParamMenu(parametricMenu?.items ?? []);
    setParamMenuTitle(parametricMenu?.title);
    let hasAnyUpdatePrivileges = false;
    let hasAnyInsertPrivileges = false;
    fields.forEach((field) => {
      if (field?.canupdate || field?.canupdateifnull) {
        hasAnyUpdatePrivileges = true;
      }
      if (field?.caninsert) {
        hasAnyInsertPrivileges = true;
      }
    });
    setPrivileges({
      canDelete: candelete,
      canInsert: caninsert || hasAnyInsertPrivileges ? true : false,
      canCopy: caninsert,
      canUpdate: canupdate || hasAnyUpdatePrivileges ? true : false,
      canView: canview,
    });
    setIsLoading(false);
  }, [moduleID, filterStatus]);

  useEffect(async () => {
    const tempChartData = await apiGetMetrics(
      moduleID,
      baseURL,
      filters[moduleID] ? filters[moduleID] : {},
      xAxisField,
      groupField,
      grouping,
      displayFunction,
      radialDisplay,
      showAverage,
      candlestickChartField
    );

    const newData = getChartData(
      showAverage,
      chartType,
      pieChartXField,
      radialDisplay,
      candlestickChartField
    );
    setData(tempChartData);
    // }
  }, [
    xAxisField,
    groupField,
    displayFunction,
    showAverage,
    pieChartXField,
    chartType,
    radialDisplay,
    candlestickChartField,
  ]);

  const changeChartParamMenuVisible = (value) => {
    setChartParamMenuVisible(value);
  };

  const invertChartVisibility = () => {
    setChartVisible(!chartVisible);
    setReportIconVisible(!reportIconVisible);
  };

  const updateFilters = (changes, config) => {
    if (config === "update") {
      const tempfilters = createFilters(filters[moduleID], changes, columns);
      setFilters({ [moduleID]: { ...tempfilters } });
    }

    if (config === "erase") {
      setFilters({ [moduleID]: {} });
    }

    invertFilterStatus();
  };

  const invertFilterStatus = () => {
    setFilterStatus(!filterStatus);
  };

  const invertGroupFieldStatus = () => {
    setGroupFieldStatus(!groupFieldStatus);
  };

  useEffect(() => {
    for (let i = 0; i < menu.length; i++) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].ME_MODULEID === moduleID) {
          setModuleParams(menu[i].children[j]);
        }
      }
    }

    for (let i = 0; i < paramMenu.length; i++) {
      if (paramMenu[i].ME_MODULEID === moduleID) {
        setModuleParams(paramMenu[i]);
      }
    }
  }, [menu, paramMenu]);

  useEffect(() => {
    setChartType("BarChart");
    setGroupField(null);
    setXAxisField(null);
    setDisplayFunction(null);
    setGrouping(null);
    setGroupMode("grouped");
    setColor("nivo");
    setStartAngle(0);
    setEndAngle(360);
    setInnerRadius(0);
    setInterpolation("natural");
    setLayout("vertical");
    setLineGroupMode(true);
    setChartBase64("");
    setShowAverage(false);
    setIs3D(true);
    setShowTrendline(false);
    setTrendline("linear");
  }, [moduleID]);

  useEffect(() => {
    invertGroupFieldStatus();
  }, [groupField]);

  useEffect(() => {
    if (chartType === "BarChart") {
      if (layout === "vertical") {
        setFinalChartType("ColumnChart");
      } else {
        setFinalChartType("BarChart");
      }
    } else if (chartType === "LineChart") {
      if (areaDisplay) {
        setFinalChartType("AreaChart");
      } else {
        setFinalChartType("LineChart");
      }
    } else {
      setFinalChartType(chartType);
    }
  }, [chartType, layout, areaDisplay]);

  useEffect(() => {
    const newOptions = getChartOptions(
      data,
      xAxisField,
      grouping,
      groupField,
      displayFunction,
      chartType,
      colorSet40,
      showAverage,
      groupMode,
      layout,
      interpolation,
      innerRadius,
      is3D,
      columns,
      commonTitles,
      showTrendline,
      trendline
    );
    setChartOptions(newOptions);
  }, [
    xAxisField,
    groupField,
    displayFunction,
    chartType,
    showAverage,
    layout,
    groupMode,
    interpolation,
    innerRadius,
    is3D,
    showTrendline,
    trendline,
  ]);

  useEffect(() => {
    let childxAxisField;
    let xAxisFieldToUse = xAxisField;

    if (xAxisField?.includes("-")) {
      let tempXAxisField = xAxisField.split(" - ");

      xAxisFieldToUse = tempXAxisField[0];
      childxAxisField = tempXAxisField[1];
    }

    let dependendDate = false;

    if (
      childxAxisField &&
      dateTypes.includes(
        columns
          .filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
          ?.DEP_FIELDS.filter((field) => field?.F_ID === childxAxisField)?.[0]
          ?.F_TYPE
      )
    ) {
      dependendDate = true;
    }

    let childGroupField;
    let groupFieldToUse = groupField;

    if (groupField?.includes("-")) {
      let tempGroupField = groupField.split(" - ");

      groupFieldToUse = tempGroupField[0];
      childGroupField = tempGroupField[1];
    }
    let dependendNumber = false;

    if (
      childGroupField &&
      numberTypes.includes(
        columns
          .filter((column) => column?.F_ID === groupFieldToUse)?.[0]
          ?.DEP_FIELDS.filter((field) => field?.F_ID === childGroupField)?.[0]
          ?.F_TYPE
      )
    ) {
      dependendNumber = true;
    }

    const dateFieldType = columns.filter(
      (column) => column?.F_ID === xAxisFieldToUse
    )?.[0]?.F_TYPE;
    const groupFieldType = columns.filter(
      (column) => column?.F_ID === groupFieldToUse
    )?.[0]?.F_TYPE;

    if (
      sumFieldOptions?.length &&
      ["sum", "pctSum"].includes(displayFunction)
    ) {
      if (dateTypes.includes(dateFieldType) || dependendDate) {
        if (groupField) {
          if (sumField) {
            setSumField(sumField);
          } else {
            setSumField(sumFieldOptions?.[0]?.id);
          }
        } else {
          setSumField("");
          if (!numberTypes.includes(groupFieldType) || dependendNumber) {
            setDisplayFunction("");
          }
        }
      } else {
        setSumField("");
        if (!numberTypes.includes(groupFieldType) && !dependendNumber) {
          setDisplayFunction("");
        }
      }
    }
  }, [xAxisField, groupField, displayFunction]);

  useEffect(() => {
    const { tempDateFieldOptions, tempGroupFieldOptions, tempSumFieldOptions } =
      createOptions(
        [],
        columns,
        foreignKeyValues,
        staticOptions,
        role,
        privileges,
        numberTypes
      );

    setDateFieldOptions(tempDateFieldOptions);
    setGroupFieldOptions(tempGroupFieldOptions);
    setSumFieldOptions(tempSumFieldOptions);
  }, [privileges]);

  const updateEraseButton = (value) => {
    setEraseButton(value);
  };

  useEffect(() => {
    let xAxisFieldToUse = xAxisField;

    if (xAxisField?.includes("-")) {
      let tempXAxisField = xAxisField.split(" - ");

      xAxisFieldToUse = tempXAxisField[0];
    }

    let xAxisFieldType = columns.filter(
      (column) => column?.F_ID === xAxisFieldToUse
    )?.[0]?.F_TYPE;

    if (!dateTypes.includes(xAxisFieldType)) {
      setGrouping("");
    }
  }, [xAxisField]);

  console.log("grouping: ", grouping);

  return (
    <Flex key={chartType} flexDirection={"column"}>
      <Navigation paramMenu={paramMenu} paramMenuTitle={paramMenuTitle} />
      <BackgroundWrapper>
        <ModuleWrapper>
          <Banner
            key={moduleParams?.T_TITLE}
            title={moduleParams?.T_TITLE ?? ""}
            path={`/module/${moduleParams.ME_MODULEID}`}
            reportIconVisible={reportIconVisible}
            moduleID={moduleID}
          />
          <Flex
            style={{
              paddingTop: "6.5rem",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Flex
                key={chartType}
                flexDirection={"row"}
                style={{ width: "100vw" }}
              >
                <Card
                  key={chartType}
                  style={{
                    borderRadius: "10px",
                    marginRight: "9px",
                    marginLeft: "9px",
                    marginTop: "10px",
                    height: width < 1525 ? "90vh" : "83vh",
                    width: "100%",
                  }}
                >
                  <Flex
                    flexDirection={width < 1525 ? "row" : "column"}
                    style={{ height: "100%" }}
                  >
                    <ChartConfigurator
                      chartVisible={chartVisible}
                      invertChartVisibility={invertChartVisibility}
                      changeChartParamMenuVisible={changeChartParamMenuVisible}
                      eraseButton={eraseButton}
                      updateEraseButton={updateEraseButton}
                      columns={columns}
                      staticOptions={staticOptions}
                      foreignKeyValues={foreignKeyValues}
                      privileges={privileges}
                      filters={filters[moduleID]}
                      updateFilters={updateFilters}
                      chartParamMenuVisible={chartParamMenuVisible}
                      xAxisFieldOptions={dateFieldOptions}
                      groupFieldOptions={groupFieldOptions}
                      sumFieldOptions={sumFieldOptions}
                    />

                    <Flex
                      key={chartType}
                      flexDirection={width < 1525 ? "column" : "row"}
                      style={{ width: "100%" }}
                    >
                      {chartVisible && (
                        <SelectChartButtons
                          columns={columns}
                          staticOptions={staticOptions}
                          foreignKeyValues={foreignKeyValues}
                          privileges={privileges}
                          filters={filters[moduleID]}
                          chartParamMenuVisible={chartParamMenuVisible}
                          changeChartParamMenuVisible={
                            changeChartParamMenuVisible
                          }
                          chartType={chartType}
                          updateFilters={updateFilters}
                        />
                      )}

                      {chartType && chartVisible && (
                        <ChartDisplayer
                          key={`${pieChartXField}-${chartType}`}
                          pieChartXField={pieChartXField}
                          setPieChartXField={setPieChartXField}
                          candlestickChartField={candlestickChartField}
                          setCandlestickChartField={setCandlestickChartField}
                          chartParamMenuVisible={chartParamMenuVisible}
                          data={data}
                          columns={columns}
                          chartOptions={chartOptions}
                          finalChartType={finalChartType}
                          changeChartParamMenuVisible={
                            changeChartParamMenuVisible
                          }
                          staticOptions={staticOptions}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
            )}
          </Flex>
        </ModuleWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};

export default UltimateChartBackend;
