import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAuthStore,
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { Radio, Space } from "antd";

const GroupMode = ({ keys = [] }) => {
  const radialDisplay = useChartStore((state) => state.radialDisplay);
  const setRadialDisplay = useChartStore((state) => state.setRadialDisplay);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const [options, setOptions] = useState([]);

  useEffect(
    () =>
      setOptions([
        { value: false, label: `Όχι` },
        { value: true, label: `Ναι` },
      ]),
    [language, commonTitles]
  );

  return (
    <FormControl sx={{ color: "rgb(8, 8, 46)", mt: 2 }}>
      <FormLabel
        sx={{ color: "rgb(84, 190, 243)", fontSize: "12px", mb: "5px" }}
        id="demo-row-radio-buttons-group-label"
      >
        {"Συνολική απεικόνιση"}
      </FormLabel>
      <Radio.Group
        onChange={(e) => setRadialDisplay(e.target.value)}
        value={radialDisplay}
        disabled={keys.length > 20 ? true : false}
      >
        <Space direction="horizontal" style={{ gap: 2 }}>
          {options.map(({ value, label }) => (
            <Radio key={value} value={value}>
              <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{label}</p>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </FormControl>
  );
};

export default GroupMode;
