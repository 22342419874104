const getChartData = (
  showAverage,
  chartType,
  pieChartXField,
  radialDisplay,
  candlestickChartField
) => {
  const data2Average = [
    ["Έτος", "Πωλήσεις", "Αγορές", "Μέση τιμή"],
    ["2020", 150, 182, 166],
    ["2021", 135, 120, 127.5],
    ["2022", 300, 220, 260],
  ];

  const data2AverageWIthAnn = [
    [
      "Έτος",
      "Πωλήσεις",
      { role: "annotation" },
      "Αγορές",
      { role: "annotation" },
      "Μέση τιμή",
      { role: "annotation" },
    ],
    ["2020", 150, 150, 182, 182, 166, 166],
    ["2021", 135, 135, 120, 120, 127.5, 127.5],
    ["2022", 300, 300, 220, 220, 260, 260],
  ];

  const data2 = [
    ["Ημερομηνία", "Πωλήσεις", "Αγορές"],
    ["2020", 150, 182],
    ["2021", 135, 120],
    ["2022", 300, 220],
  ];

  const data2ann = [
    [
      "Ημερομηνία",
      "Πωλήσεις",
      { role: "annotation" },
      "Αγορές",
      { role: "annotation" },
    ],
    ["2020", 150, 150, 182, 182],
    ["2021", 135, 135, 120, 120],
    ["2022", 300, 300, 220, 220],
  ];

  const data2num = [
    ["Πωλήσεις", "Αγορές"],
    [150, 182],
    [135, 120],
    [300, 220],
  ];

  const data3 = [
    ["Πωλήσεις", "Αγορές", "a", "b"],
    [50, 100, 120, 130],
    [150, 182, 100, 30],
    [135, 120, 150, 51],
    [300, 220, 200, 20],
  ];

  const data4 = [
    ["Πωλήσεις", "Αγορές", "Επιστροφές"],
    [50, 100, 120],
    [150, 182, 30],
    [135, 120, 51],
    [300, 220, 20],
  ];

  const data4ann = [
    [
      "Πωλήσεις",
      "Αγορές",
      { role: "annotation" },
      "Επιστροφές",
      { role: "annotation" },
    ],
    [50, 100, "(50, 100)", 120, "(50, 120)"],
    [150, 182, "(150, 182)", 30, "(150, 30)"],
    [135, 120, "(135, 120)", 51, "(135, 51)"],
    [300, 220, "(300, 220)", 20, "(300, 20)"],
  ];

  const data = [
    [
      "Ημερομηνία",
      { role: "annotation" },
      "Πωλήσεις",
      { role: "annotation" },
      "Αγορές",
    ],
    ["2020", 150, 182],
    ["2021", 135, 120],
    ["2022", 300, 220],
  ];

  if (chartType === "ScatterChart") {
    return data4ann;
  }

  if (showAverage) {
    return data2AverageWIthAnn;
  }

  if (chartType === "PieChart" && radialDisplay) {
    const pieData = [
      {
        id: "2020",
        data: [
          { x: "Πωλήσεις", y: 150 },
          { x: "Αγορές", y: 182 },
        ],
      },
      {
        id: "2021",
        data: [
          { x: "Πωλήσεις", y: 135 },
          { x: "Αγορές", y: 120 },
        ],
      },
      {
        id: "2022",
        data: [
          { x: "Πωλήσεις", y: 300 },
          { x: "Αγορές", y: 220 },
        ],
      },
    ];

    return pieData;
  }

  if (chartType === "PieChart" && !radialDisplay) {
    const pieData = {
      2020: [
        ["Έτος", "2020"],
        ["Πωλήσεις", 150],
        ["Αγορές", 182],
      ],
      2021: [
        ["Έτος", "2021"],
        ["Πωλήσεις", 135],
        ["Αγορές", 120],
      ],
      2022: [
        ["Έτος", "2022"],
        ["Πωλήσεις", 300],
        ["Αγορές", 220],
      ],
    };

    return pieData?.[pieChartXField] ?? data2;
  }

  if (chartType === "CandlestickChart") {
    const data = {
      Πωλήσεις: [
        ["Έτος", "a", "b", "c", "d"],
        ["2021", 20, 28, 38, 45],
        ["2022", 31, 38, 55, 66],
        ["2023", 50, 55, 77, 80],
      ],
      Αγορές: [
        ["Έτος", "a", "b", "c", "d"],
        ["2021", 11, 28, 18, 65],
        ["2022", 20, 100, 55, 106],
        ["2023", 2, 55, 77, 80],
      ],
    };

    return data?.[candlestickChartField];
  }

  return data2ann;
};

export default getChartData;
