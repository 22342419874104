const getChartOptions = (
  data = [],
  xField,
  grouping,
  yField,
  displayFunction,
  chartType,
  colorSet,
  showAverage,
  groupMode,
  layout,
  interpolation,
  innerRadius,
  is3D,
  columns,
  commonTitles,
  showTrendline,
  trendline,
  chartColor9
) => {
  const chartTitle =
    columns.filter((column) => column.F_ID === yField)?.[0]?.T_TITLE ?? "";

  const xAxisTitle =
    columns.filter((column) => column.F_ID === xField)?.[0]?.T_TITLE ?? "";

  const displayFunctionOptions = {
    count: commonTitles.count,
    pctCount: commonTitles.pctCount,
  };

  let trendlines = {};

  for (let i = 1; i < data?.length; i++) {
    trendlines = {
      ...trendlines,
      [i - 1]: {
        type: trendline,
        color: colorSet?.[i - 1] ?? "fff",
        visibleInLegend: true,
      },
    };
  }

  if (chartType === "BarChart") {
    const options = {
      title: chartTitle,
      hAxis: {
        title:
          layout === "horizontal"
            ? displayFunctionOptions?.[displayFunction] ?? ""
            : xAxisTitle,
        titleTextStyle: { color: "#333" },
        format:
          layout === "horizontal" && displayFunction === "pctCount"
            ? "#.#'%'"
            : "",
      },
      vAxis: {
        title:
          layout === "horizontal"
            ? xAxisTitle
            : displayFunctionOptions?.[displayFunction] ?? "",
        minValue: layout === "vertical" && 0,
        format:
          layout === "vertical" && displayFunction === "pctCount"
            ? "#.#'%'"
            : "",
      },
      chartArea: {
        width: "60%",
        height: "80%",
      },
      colors: colorSet,
      series: showAverage && {
        [data[0].length - 3]: {
          type: "line",
          lineDashStyle: [2, 2],
          color: "red",
        },
      },
      isStacked: groupMode === "stacked" ? true : false,
      legend: { textStyle: { fontSize: 11 } },
    };

    return options;
  }

  if (chartType === "LineChart") {
    const options = {
      title: chartTitle,
      hAxis: {
        title: xAxisTitle,
        titleTextStyle: { color: "#333" },
      },
      vAxis: {
        title: displayFunctionOptions?.[displayFunction] ?? "",
        minValue: 0,
        format: displayFunction === "pctCount" ? "#.#'%'" : "",
      },
      chartArea: {
        width: "60%",
        height: "80%",
      },
      colors: colorSet,
      series: showAverage && {
        [data[0].length - 1]: { type: "line", lineDashStyle: [2, 2] },
      },
      curveType: interpolation === "natural" ? "function" : "",
      legend: { textStyle: { fontSize: 11 } },
    };

    return options;
  }

  if (chartType === "PieChart") {
    const options = {
      title: chartTitle,
      chartArea: {
        width: "60%",
        height: "80%",
      },
      colors: colorSet,
      pieHole: innerRadius,
      pieSliceText: displayFunction !== "pctCount" && "value",
      is3D: is3D,
      legend: { textStyle: { fontSize: 11 } },
    };

    return options;
  }

  if (chartType === "ScatterChart") {
    const options = {
      title: chartTitle,
      chartArea: {
        width: "60%",
        height: "80%",
      },
      hAxis: {
        title: xAxisTitle,
        titleTextStyle: { color: "#333" },
      },
      vAxis: {
        title: displayFunctionOptions?.[displayFunction] ?? "",
        minValue: 0,
        format: displayFunction === "pctCount" ? "#.#'%'" : "",
      },
      colors: colorSet,
      legend: { textStyle: { fontSize: 11 } },
      trendlines: showTrendline ? trendlines : {},
    };

    return options;
  }

  if (chartType === "CandlestickChart") {
    const options = {
      title: chartTitle,
      chartArea: {
        width: "60%",
        height: "80%",
      },
      hAxis: {
        title: xAxisTitle,
        titleTextStyle: { color: "#333" },
      },
      vAxis: {
        title: displayFunctionOptions?.[displayFunction] ?? "",
        minValue: 0,
        format: displayFunction === "pctCount" ? "#.#'%'" : "",
      },
      colors: colorSet,
      series: showAverage && {
        [data[0].length - 1]: { pointShape: "star" },
      },
      legend: "none",
    };

    return options;
  }

  return {
    title: chartTitle,
    hAxis: {
      title: xAxisTitle,
      titleTextStyle: { color: "#333" },
    },
    vAxis: {
      title: displayFunctionOptions?.[displayFunction] ?? "",
      minValue: 0,
      format: displayFunction === "pctCount" ? "#.#'%'" : "",
    },
    chartArea: {
      width: "60%",
      height: "80%",
    },
    colors: colorSet,
    curveType: "function",
    legend: {
      textStyle: { fontSize: 11 },
    },
  };
};

export default getChartOptions;
