import {
  useCommonTitlesStore,
  useModuleStore,
} from "../../../../store/useStores";
import { Select } from "antd";
import { useChartStore } from "../../../../store/useStores";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";
import Flex from "../../../../helpers/components/Flex";

const { Option } = Select;

const SumField = ({ sumFieldOptions, columns, privileges }) => {
  const xAxisField = useChartStore((state) => state.xAxisField);
  const groupField = useChartStore((state) => state.groupField);
  const sumField = useChartStore((state) => state.sumField);
  const setSumField = useChartStore((state) => state.setSumField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);

  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const dateColumns = columns
    .filter((column) => dateTypes.includes(column.F_TYPE))
    .map((value) => value.F_ID);

  let dependendDate = false;

  if (
    childxAxisField &&
    dateTypes.includes(
      columns
        .filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
        ?.DEP_FIELDS.filter((field) => field?.F_ID === childxAxisField)?.[0]
        ?.F_TYPE
    )
  ) {
    dependendDate = true;
  }

  let dependendNumber = false;

  if (
    childGroupField &&
    numberTypes.includes(
      columns
        .filter((column) => column?.F_ID === groupFieldToUse)?.[0]
        ?.DEP_FIELDS.filter((field) => field?.F_ID === childGroupField)?.[0]
        ?.F_TYPE
    )
  ) {
    dependendNumber = true;
  }

  let showSumField = false;

  const groupFieldType = columns.filter(
    (column) => column.F_ID === groupField
  )?.[0]?.F_TYPE;

  if (sumFieldOptions.length) {
    if (dateColumns.includes(xAxisField) || dependendDate) {
      if (groupField && ["sum", "pctSum"].includes(displayFunction)) {
        showSumField = true;
      }
    }
  }

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  return (
    <Flex
      flexDirection={"column"}
      style={{ gap: 5, display: !showSumField && "none" }}
    >
      <p
        style={{
          color: "rgb(8, 8, 46)",
          fontWeight: 500,
          fontSize: 12,
          marginBottom: 5,
        }}
      >
        {commonTitles["CO|1|SUMFIELD"]}:
      </p>
      <div key={displayFunction} className="select">
        <Select
          style={{
            width: 150,
            marginLeft: width < 1525 ? 0 : 0,
            marginRight: 10,
            marginTop: width < 1525 ? 0 : -30,
            marginBottom: -50,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{
            minWidth: "max-content",
            paddingLeft: 5,
            paddingRight: 5,
            overflowY: "auto",
          }}
          onChange={(e) => setSumField(e)}
          defaultValue={sumField}
          placeholder={commonTitles["CO|1|SUMFIELD"]}
          virtual={false}
        >
          <Option className="option" key={""} value={null} />
          {sumFieldOptions.map(({ id, view }) => (
            <Option className="option" key={`${id}-${view}`} value={id}>
              {view}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default SumField;
