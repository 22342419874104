import Flex from "../../../../../helpers/components/Flex";
import DisplayModalInfo from "./DisplayModalInfo";
import apiGetData from "../../../../../api/endpoints/apiGetData";
import { useState, useEffect } from "react";
import {
  useAuthStore,
  useAlertStore,
  useCommonTitlesStore,
  useModuleStore,
} from "../../../../../store/useStores";
import createData from "../../../../module/helpers/createData";
import countTotalRowWidth from "../../../../module/helpers/countTotalRowWidth";
import htmlCodesRemover from "../../htmlCodesRemover";
import { Button } from "antd";
import apiGetModuleFields from "../../../../../api/endpoints/apiGetModuleFields";
import apiGetModulesList from "../../../../../api/endpoints/apiGetModulesList";

const ButtonType = ({
  title = "",
  row = {},
  parentField = "",
  refModule = "",
  refField = "",
}) => {
  const buttonModalVisible = useModuleStore(
    (state) => state.buttonModalVisible
  );

  const setButtonModalVisible = useModuleStore(
    (state) => state.setButtonModalVisible
  );

  const buttonData = useModuleStore((state) => state.buttonData);
  const setButtonData = useModuleStore((state) => state.setButtonData);
  const buttonAllData = useModuleStore((state) => state.buttonAllData);
  const setButtonAllData = useModuleStore((state) => state.setButtonAllData);
  const buttonRestData = useModuleStore((state) => state.buttonRestData);
  const setButtonRestData = useModuleStore((state) => state.setButtonRestData);
  const buttonColumnData = useModuleStore((state) => state.buttonColumnData);
  const setButtonColumnData = useModuleStore(
    (state) => state.setButtonColumnData
  );
  const buttonFilters = useModuleStore((state) => state.buttonFilters);
  const setButtonFilters = useModuleStore((state) => state.setButtonFilters);
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const fromOtherModuleTypes = useModuleStore(
    (state) => state.fromOtherModuleTypes
  );

  const [moduleTitle, setModuleTitle] = useState("");
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const alertsChanged = useAlertStore((state) => state.alertsChanged);
  const setAlertsChanged = useAlertStore((state) => state.setAlertsChanged);
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("ASC");

  let totalRowWidth = 1;

  useEffect(async () => {
    const { modules } = await apiGetModulesList(baseURL);
    const title =
      modules.filter((module) => module.M_ID === refModule)?.[0]?.T_TITLE ?? "";
    setModuleTitle(title);
  }, []);

  const showModal = async () => {
    let moduleID = refModule ?? "";
    let parentID = parentField && parentField !== "0" ? parentField : "";
    let moduleFieldID = refField ?? "";

    const serverData = await apiGetModuleFields(baseURL, moduleID);

    const parentFieldType = serverData?.rows?.filter(
      (field) => field.F_ID === moduleFieldID
    )?.[0]?.F_TYPE;

    let filterValue;

    if (["6", "7", "28"].includes(parentFieldType)) {
      const { staticoptions, fkvalues } = await apiGetData(
        moduleID,
        baseURL,
        {},
        orderBy,
        orderDirection,
        0,
        1
      );

      let newValue;
      if (fromOtherModuleTypes.includes(parentFieldType) && fkvalues) {
        newValue =
          Object.entries(fkvalues[moduleFieldID]).filter(
            ([key, value]) => value.view === row[parentID]
          )?.[0]?.[0] ?? "";
      }

      if (["6"].includes(parentFieldType) && staticoptions) {
        newValue =
          Object.entries(staticoptions[moduleFieldID]).filter(
            ([key, value]) => value.TITLE === row[parentID]
          )?.[0]?.[0] ?? "";
      }

      filterValue = newValue ? [newValue] : [];
    } else if (dateTypes.includes(parentFieldType)) {
      filterValue = { from: row[parentID], to: row[parentID] };
    } else {
      filterValue = row[parentID];
    }

    //let filtersToServer = moduleFieldID ? { [moduleFieldID]: filterValue } : {};
    let filtersToServer = moduleFieldID
      ? { [moduleFieldID]: [row?.[`${parentField}-server`] ?? ""] }
      : {};
    setButtonFilters(filtersToServer);

    const {
      rows,
      fields,
      staticoptions,
      fkvalues,
      candelete,
      caninsert,
      canupdate,
      canview,
      projectspecific,
      menu: parametricMenu,
    } = await apiGetData(
      moduleID,
      baseURL,
      filtersToServer,
      orderBy,
      orderDirection,
      0,
      1
    );

    if (!orderBy) {
      setOrderBy(fields?.[0]?.F_ID);
    }

    const tempData = createData(
      rows,
      fields,
      fkvalues,
      staticoptions,
      baseURL,
      dateTypes
    );

    if (!filterValue || filterValue?.length === 0) {
      setButtonData({});
      setButtonAllData(tempData);
    } else {
      setButtonData(tempData);
      setButtonAllData(tempData);
    }

    let tempColumnData = [];
    tempColumnData =
      fields
        ?.filter((field) => field.F_LIST === "Y")
        .map((field) => ({
          ...field,
          T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
        })) ?? [];
    setButtonColumnData(tempColumnData);

    totalRowWidth = countTotalRowWidth(fields);

    let hasAnyUpdatePrivileges = false;
    let hasAnyInsertPrivileges = false;
    fields.forEach((field) => {
      if (field?.canupdate || field?.canupdateifnull) {
        hasAnyUpdatePrivileges = true;
      }
      if (field?.caninsert) {
        hasAnyInsertPrivileges = true;
      }
    });

    const tempRestData = {
      existingRowIDs: Object.keys(rows),
      privileges: {
        canDelete: candelete,
        canInsert: caninsert || hasAnyInsertPrivileges ? true : false,
        canCopy: caninsert,
        canUpdate: canupdate || hasAnyUpdatePrivileges ? true : false,
        canView: canview,
      },
      foreignKeyValues: fkvalues,
      staticOptions: staticoptions,
      isProjectSpecific: projectspecific === "N" ? false : true,
      paramMenu: parametricMenu?.items ?? [],
      paramMenuTitle: parametricMenu?.title ?? "",
    };

    setButtonRestData(tempRestData);
    setButtonModalVisible([row.ROWID]);
  };

  useEffect(() => {
    totalRowWidth = countTotalRowWidth(buttonColumnData);
  }, [buttonColumnData]);

  const cancelModal = () => {
    setButtonModalVisible([]);
    setButtonData({});
    setButtonAllData({});
    setButtonColumnData([]);
    setButtonFilters({});
    setButtonRestData({});
  };

  const insertData = (changes) => {
    setButtonData({ ...buttonAllData, ...changes });
    setButtonAllData({ ...buttonAllData, ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const deleteData = (changes) => {
    setButtonData({ ...changes });
    setButtonAllData({ ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const updateData = (changes) => {
    setButtonData({ ...buttonData, ...changes });
    setButtonAllData({ ...buttonAllData, ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const updateOrderConfig = (id, direction) => {
    if (id && direction === null) {
      setOrderBy("");
      setOrderDirection("");
    }

    if (id) {
      setOrderBy(id);
      setOrderDirection(direction);
    }
  };

  const changeDisplayedColumns = (key, viewRights) => {
    const columnsTemp = buttonColumnData
      .filter((column) => viewRights[column.F_ID])
      .map((column) => {
        let isImportant = column.F_LIST;

        if (key === "") {
          isImportant = "Y";
        } else {
          if (column.F_ID === key) {
            isImportant = column.F_LIST === "Y" ? "" : "Y";
          }
        }

        return { ...column, F_LIST: isImportant };
      });

    setButtonColumnData(columnsTemp);
  };

  totalRowWidth = countTotalRowWidth(buttonColumnData);

  return (
    <Flex flexDirection={"column"}>
      <Button
        shape="round"
        className="dropdown"
        onClick={() => {
          showModal();
        }}
        style={{
          backgroundColor: "rgb(8, 8, 46)",
          color: "#fff",
          width: "max-content",
          marginLeft: 15,
        }}
      >
        {commonTitles["CO|1|VIEW"]}
      </Button>
      {buttonModalVisible.includes(row.ROWID) && (
        <DisplayModalInfo
          key={`${buttonColumnData.length}-${Object.keys(buttonData).length}`}
          visible={buttonModalVisible.includes(row.ROWID)}
          onCancel={cancelModal}
          moduleTitle={moduleTitle}
          modalTitle={`${title} (πληροφορίες από ${moduleTitle})`}
          columnData={buttonColumnData}
          data={{ ...buttonData }}
          filters={{}}
          staticOptions={buttonRestData?.staticOptions ?? {}}
          foreignKeyValues={buttonRestData?.foreignKeyValues ?? {}}
          privileges={buttonRestData?.privileges ?? {}}
          updateData={updateData}
          deleteData={deleteData}
          insertData={insertData}
          updateOrderConfig={updateOrderConfig}
          moduleID={refModule ?? ""}
          changeDisplayedColumns={changeDisplayedColumns}
          totalRowWidth={totalRowWidth}
          autoCompletedFields={buttonFilters}
        />
      )}
    </Flex>
  );
};

export default ButtonType;
