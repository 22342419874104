import { Card, Spin } from "antd";

const Loader = () => {
  return (
    <Card
      style={{
        borderRadius: "10px",
        paddingBottom: -8,
        margin: 10,
        minHeight: 78,
        width: "100%",
      }}
      bodyStyle={{ display: "flex", justifyContent: "center" }}
    >
      <Spin />
    </Card>
  );
};

export default Loader;
