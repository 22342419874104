import React from "react";
import { Select } from "antd";
import {
  useAuthStore,
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const XAxisField = ({ columns, privileges, xAxisFieldOptions }) => {
  const role = useAuthStore((state) => state.role);
  const xAxisField = useChartStore((state) => state.xAxisField);
  const setXAxisField = useChartStore((state) => state.setXAxisField);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  // let notAcceptedTypes = ["1", "24", "25", "26", "27", "29", "30"];

  // const xFieldOptions = columns.filter(
  //   (column) =>
  //     viewRights[column.F_ID] && !notAcceptedTypes.includes(column.F_TYPE)
  // );

  return (
    //<Flex flexDirection={width < 1525 ? "column" : "row"}>
    <Flex flexDirection={"column"} style={{ gap: 5 }}>
      <p style={{ color: "rgb(8, 8, 46)", fontWeight: 500, fontSize: 12 }}>
        {commonTitles["CO|1|XAXISFLD"]}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 150,
            marginLeft: 0,
            marginRight: 10,
            marginTop: width < 1525 ? 0 : -50,
            marginBottom: -50,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{
            minWidth: "max-content",
            paddingLeft: 5,
            paddingRight: 5,
            overflowY: "auto",
          }}
          onChange={(e) => setXAxisField(e)}
          defaultValue={xAxisField}
          placeholder={`${commonTitles["CO|1|XAXISFLD"]}`}
          virtual={false}
        >
          <Option className="option" key={""} value={""} />
          {xAxisFieldOptions.map(({ id, view }) => (
            <Option className="option" key={`${id}-${view}`} value={id}>
              {view}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default XAxisField;
