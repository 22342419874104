import { useChartStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import GroupMode from "./options/GroupMode";
import Layout from "./options/Layout";
import ShowAverage from "./options/ShowAverage";
import Interpolation from "./options/Interpolation";
import InnerRadius from "./options/InnerRadius";
import ThreeDimensionalDisplay from "./options/ThreeDimensionalDisplay";
import RadialDisplay from "./options/RadialDisplay";
import Trendline from "./options/Trendline";
import AreaDisplay from "./options/AreaDisplay";

const ChartSettingsDisplayer = () => {
  const chartType = useChartStore((state) => state.chartType);

  if (chartType === "BarChart") {
    return (
      <Flex
        flexDirection={"column"}
        style={{
          width: "max-content",
          justifyContent: "flex-start",
          gap: 30,
          marginTop: 10,
          height: "100%",
        }}
      >
        <Layout />
        <GroupMode />
        <ShowAverage />
      </Flex>
    );
  }

  if (chartType === "LineChart") {
    return (
      <Flex
        flexDirection={"column"}
        style={{
          width: "max-content",
          justifyContent: "center",
          gap: 30,
          marginTop: 10,
        }}
      >
        <Interpolation />
        <ShowAverage />
        <AreaDisplay />
      </Flex>
    );
  }

  if (chartType === "PieChart") {
    return (
      <Flex
        flexDirection={"column"}
        style={{
          width: "max-content",
          justifyContent: "center",
          gap: 30,
          marginTop: 10,
        }}
      >
        <InnerRadius />
        <ThreeDimensionalDisplay />
        <RadialDisplay />
      </Flex>
    );
  }

  if (chartType === "ScatterChart") {
    return (
      <Flex
        flexDirection={"column"}
        style={{
          width: "max-content",
          justifyContent: "center",
          gap: 30,
          marginTop: 10,
        }}
      >
        <Trendline />
      </Flex>
    );
  }

  return <div />;
};

export default ChartSettingsDisplayer;
