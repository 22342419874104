import { useCommonTitlesStore } from "../../../../store/useStores";
import { Select } from "antd";
import { useChartStore } from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const Grouping = () => {
  const grouping = useChartStore((state) => state.grouping);
  const setGrouping = useChartStore((state) => state.setGrouping);
  const chartType = useChartStore((state) => state.chartType);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  const options = [
    { value: "D", label: commonTitles.daily },
    { value: "M", label: commonTitles.monthly },
    { value: "Y", label: commonTitles.yearly },
  ];

  const trimmedOptions = [{ value: "Y", label: commonTitles.yearly }];

  return (
    <Flex flexDirection={"column"} style={{ gap: 5 }}>
      <p style={{ color: "rgb(8, 8, 46)", fontWeight: 500, fontSize: 12 }}>
        {commonTitles.grouping}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 150,
            marginLeft: width < 1525 ? 0 : 0,
            marginRight: 10,
            marginTop: width < 1525 ? 0 : -50,
            marginBottom: -50,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{
            minWidth: "max-content",
            paddingLeft: 5,
            paddingRight: 5,
            overflowY: "auto",
          }}
          onChange={(e) => setGrouping(e)}
          defaultValue={grouping}
          placeholder={`${commonTitles.grouping}`}
          virtual={false}
        >
          <Option className="option" key={""} value={""} />
          {/* {chartType === "Pie"
          ? trimmedOptions.map(({ value, label }) => (
              <Option
                className="option"
                key={`${value}-${label}`}
                value={value}
                label={label}
              >
                {label}
              </Option>
            ))
          : options.map(({ value, label }) => (
              <Option
                className="option"
                key={`${value}-${label}`}
                value={value}
                label={label}
              >
                {label}
              </Option>
            ))} */}
          {options.map(({ value, label }) => (
            <Option
              className="option"
              key={`${value}-${label}`}
              value={value}
              label={label}
            >
              {label}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default Grouping;
