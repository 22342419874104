import axios from "axios";

const apiGetMetrics = async (
  moduleid,
  baseURL,
  filters,
  xAxisField,
  groupField,
  grouping = null,
  displayFunction,
  sumField,
  chartType = "BarChart",
  radialDisplay = false,
  showAverage = false,
  candlestickField = null
) => {
  const url = `${baseURL}/metrics_api/getdata`;

  try {
    const response = await axios.post(
      url,
      {
        moduleid: moduleid,
        filters: filters?.[moduleid] ?? {},
        xAxisField: xAxisField,
        yAxisField: groupField,
        grouping: grouping,
        displayfunction: displayFunction,
        sumField: sumField,
        charttype: chartType,
        radialDisplay: radialDisplay,
        showAverage: showAverage,
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log(
      "GetMetrics: metrics_api/getdata: response.data: ",
      response.data
    );
    return response.data;
  } catch (err) {}
};

export default apiGetMetrics;
