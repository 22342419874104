import { useChartStore } from "../../../store/useStores";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import { PieChartOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const PieChartButton = () => {
  const { width } = useWindowDimensions();
  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);

  return (
    <Tooltip placement={width < 1525 ? "bottom" : "right"} title={"Pie chart"}>
      <PieChartOutlined
        style={{
          backgroundColor: chartType === "PieChart" && "rgb(84, 190, 243)",
          color: chartType === "PieChart" ? "#fff" : "rgb(8, 8, 46)",
          width: "max-content",
          padding: 8,
          fontSize: "30px",
        }}
        onClick={() => setChartType("PieChart")}
      />
    </Tooltip>
  );
};

export default PieChartButton;
