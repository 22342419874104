import Flex from "../../../../helpers/components/Flex";
import { Tooltip, Button, Badge } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useCommonTitlesStore } from "../../../../store/useStores";
import { useState } from "react";
import FiltersModal from "../../../ultimateTable/helpers/modals/filtersModal/FiltersModal";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const FilterButton = ({
  filters,
  columns,
  staticOptions,
  privileges,
  foreignKeyValues,
  updateFilters,
  moduleID,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();
  const [filtersModalVisible, setFiltersModalVisible] = useState(false);

  const filterCount = filters ? Object.keys(filters).length : 0;

  const applyFilters = (changes, config) => {
    updateFilters(changes, config);
    setFiltersModalVisible(false);
  };
  const cancelFiltersModal = () => {
    setFiltersModalVisible(false);
  };
  const eraseFilters = () => {
    updateFilters(null, "erase");
    setFiltersModalVisible(false);
  };

  return (
    <Flex>
      <Tooltip
        placement={width < 1525 ? "right" : "bottom"}
        title={commonTitles["CO|1|FILTERS"]}
      >
        <Button
          style={{
            backgroundColor: filterCount && "rgb(8, 8, 46)",
            color: filterCount ? "#fff" : "rgb(8, 8, 46)",
            width: filterCount ? "max-content" : 50,
            paddingTop: 5,
            paddingBottom: 5,
          }}
          shape="round"
          icon={<FilterOutlined />}
          onClick={() => setFiltersModalVisible(true)}
        >
          {filters && filterCount > 0 && (
            <Badge
              size="small"
              style={{
                marginLeft: 2,
                marginBottom: 5,
                paddingBottom: 2,
                backgroundColor: "rgb(8, 8, 46)",
                border: "1px solid rgb(8, 8, 46)",
              }}
              count={filterCount}
            />
          )}
        </Button>
      </Tooltip>
      {filtersModalVisible && (
        <FiltersModal
          visible={filtersModalVisible}
          columns={columns}
          onSave={applyFilters}
          onCancel={cancelFiltersModal}
          onErase={eraseFilters}
          filters={filters}
          foreignKeyValues={foreignKeyValues}
          staticOptions={staticOptions}
          privileges={privileges}
          moduleID={moduleID}
        />
      )}
    </Flex>
  );
};

export default FilterButton;
