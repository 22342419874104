import Flex from "../../../helpers/components/Flex";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import BarChartButton from "./BarChartButton";
import PieChartButton from "./PieChartButton";
import LineChartButton from "./LineChartButton";
import ScatterChartButton from "./ScatterChartButton";
import CandlestickChartButton from "./CandlestickChartButton";

const SelectChartButtons = () => {
  const { width } = useWindowDimensions();

  return (
    <Flex
      flexDirection={width < 1525 ? "row" : "column"}
      style={{
        width: width < 1525 ? "95%" : "5%",
        height: width < 1525 ? "max-content" : "60vh",
        gap: 25,
        justifyContent: "center",
        marginBottom: width < 1525 && "1rem",
      }}
    >
      <BarChartButton />
      <PieChartButton />
      <LineChartButton />
      <ScatterChartButton />
      <CandlestickChartButton />
    </Flex>
  );
};

export default SelectChartButtons;
