import { useCommonTitlesStore } from "../../../../store/useStores";
import { Select } from "antd";
import { useChartStore } from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const DisplayFunction = () => {
  const displayFunction = useChartStore((state) => state.displayFunction);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const { width } = useWindowDimensions();

  const displayFunctionOptions = [
    { value: "count", label: commonTitles.count },
    { value: "pctCount", label: commonTitles.pctCount },
    { value: "sum", label: commonTitles.sum },
    { value: "pctSum", label: commonTitles.pctSum },
  ];

  return (
    <Flex flexDirection={"column"} style={{ gap: 5 }}>
      <p style={{ color: "rgb(8, 8, 46)", fontWeight: 500, fontSize: 12 }}>
        {commonTitles.displayFunction}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 150,
            marginLeft: width < 1525 ? 0 : 0,
            marginRight: 10,
            marginTop: width < 1525 ? 0 : -50,
            marginBottom: -50,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{
            minWidth: "max-content",
            paddingLeft: 5,
            paddingRight: 5,
            overflowY: "auto",
          }}
          onChange={(e) => setDisplayFunction(e)}
          defaultValue={displayFunction}
          placeholder={`${commonTitles.displayFunction}`}
          virtual={false}
        >
          <Option className="option" key={""} value={""} />
          {displayFunctionOptions.map(({ label, value }) => (
            <Option className="option" key={`${value}-${label}`} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default DisplayFunction;
