import React, { useState } from "react";
import XAxisField from "./menu/XAxisField";
import Grouping from "./menu/Grouping";
import GroupField from "./menu/GroupField";
import DisplayFunction from "./menu/DisplayFunction";
import { Layout } from "antd";
import { useChartStore, useModuleStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import ConfiguratorButtons from "./buttons/ConfiguratorButtons";
import { LeftOutlined, RightOutlined, StarTwoTone } from "@ant-design/icons";
import HideShowConfigurator from "./HideShowConfigurator";
import SumField from "./menu/SumField";

const { Content } = Layout;

const ChartConfigurator = ({
  chartVisible,
  invertChartVisibility,
  changeChartParamMenuVisible,
  eraseButton,
  updateEraseButton,
  columns,
  staticOptions,
  foreignKeyValues,
  privileges,
  filters,
  updateFilters,
  chartParamMenuVisible,
  xAxisFieldOptions,
  groupFieldOptions,
  sumFieldOptions,
}) => {
  const xAxisField = useChartStore((state) => state.xAxisField);
  const showConfigurator = useChartStore((state) => state.showConfigurator);
  const setShowConfigurator = useChartStore(
    (state) => state.setShowConfigurator
  );
  const dateTypes = useModuleStore((state) => state.dateTypes);

  const { width } = useWindowDimensions();

  const dateColumns = columns
    .filter((column) => dateTypes.includes(column.F_TYPE))
    .map((item) => item.F_ID);

  return (
    <Content
      key={xAxisField}
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex
        style={{
          gap: "1rem",
          alignItems: width < 1525 ? "flex-start" : "center",
          justifyContent: width < 1525 ? "flex-start" : "space-between",
          width: width < 1525 ? "max-content" : "95vw",
          heigth: "100%",
          marginBottom: "2rem",
        }}
        flexDirection={width < 1525 ? "column" : "row"}
      >
        <HideShowConfigurator
          showConfigurator={showConfigurator}
          setShowConfigurator={setShowConfigurator}
          chartVisible={chartVisible}
        />
        {showConfigurator && (
          <>
            <XAxisField
              columns={columns}
              privileges={privileges}
              xAxisFieldOptions={xAxisFieldOptions}
            />
            {dateColumns.includes(xAxisField) && <Grouping />}
            <GroupField
              columns={columns}
              privileges={privileges}
              groupFieldOptions={groupFieldOptions}
            />
            <DisplayFunction />
            <SumField sumFieldOptions={sumFieldOptions} columns={columns} />
            <ConfiguratorButtons
              chartVisible={chartVisible}
              eraseButton={eraseButton}
              updateEraseButton={updateEraseButton}
              invertChartVisibility={invertChartVisibility}
              filters={filters}
              columns={columns}
              staticOptions={staticOptions}
              foreignKeyValues={foreignKeyValues}
              privileges={privileges}
              chartParamMenuVisible={chartParamMenuVisible}
              changeChartParamMenuVisible={changeChartParamMenuVisible}
              updateFilters={updateFilters}
            />
          </>
        )}
      </Flex>
    </Content>
  );
};

export default ChartConfigurator;
