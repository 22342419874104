import { useChartStore } from "../../../store/useStores";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import { DotChartOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const ScatterChartButton = () => {
  const { width } = useWindowDimensions();
  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);

  return (
    <Tooltip
      placement={width < 1525 ? "bottom" : "right"}
      title={"Scatter chart"}
    >
      <DotChartOutlined
        style={{
          backgroundColor: chartType === "ScatterChart" && "rgb(84, 190, 243)",
          color: chartType === "ScatterChart" ? "#fff" : "rgb(8, 8, 46)",
          width: "max-content",
          padding: 8,
          fontSize: "30px",
        }}
        onClick={() => setChartType("ScatterChart")}
      />
    </Tooltip>
  );
};

export default ScatterChartButton;
