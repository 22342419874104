import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";
import { useChartStore } from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";
import CalculateButton from "./CalculateButton";
import EraseButton from "./EraseButton";
import FilterButton from "./FilterButton";
import SettingsButton from "./SettingsButton";

const ConfiguratorButtons = ({
  chartVisible,
  eraseButton,
  updateEraseButton,
  invertChartVisibility,
  filters,
  columns,
  staticOptions,
  foreignKeyValues,
  privileges,
  chartParamMenuVisible,
  changeChartParamMenuVisible,
  updateFilters,
}) => {
  const { width } = useWindowDimensions();
  const chartType = useChartStore((state) => state.chartType);

  return (
    <Flex
      flexDirection={width < 1525 ? "column" : "row"}
      style={{
        gap: 20,
        marginTop: width < 1525 && "1rem",
        marginLeft: width < 1525 && "1.5rem",
      }}
    >
      {!eraseButton ? (
        <CalculateButton
          updateEraseButton={updateEraseButton}
          invertChartVisibility={invertChartVisibility}
        />
      ) : (
        <EraseButton
          updateEraseButton={updateEraseButton}
          invertChartVisibility={invertChartVisibility}
        />
      )}
      <FilterButton
        filters={filters}
        columns={columns}
        staticOptions={staticOptions}
        foreignKeyValues={foreignKeyValues}
        privileges={privileges}
        updateFilters={updateFilters}
      />

      {chartType && chartVisible && (
        <SettingsButton
          chartParamMenuVisible={chartParamMenuVisible}
          changeChartParamMenuVisible={changeChartParamMenuVisible}
        />
      )}
    </Flex>
  );
};

export default ConfiguratorButtons;
