import Flex from "../../helpers/components/Flex";
import { Tooltip } from "antd";
import { TableRow, TableCell } from "@mui/material";
import { useAuthStore } from "../../store/useStores";
//import chooseTableCellStyle from "./helpers/chooseTableCellStyle";

const UltimateTableTotals = ({
  totals,
  columns,
  privileges,
  totalRowWidth,
  selection,
  dense,
  //frozenColumns = [],
}) => {
  const role = useAuthStore((state) => state.role);
  const language = useAuthStore((state) => state.language);

  const TotalsBottomBorder = () => {
    return (
      <div
        style={{
          borderBottom: "0.5px solid #CFD1D1",
          width: "100%",
        }}
      />
    );
  };

  let totalOptions = [
    {
      key: "SUM",
      view: totals?.titles?.SUM,
      abbr: totals?.titles?.SUM.slice(0, 3) + ".",
    },
    {
      key: "MIN",
      view: totals?.titles?.MIN,
      abbr: totals?.titles?.MIN.slice(0, 4) + ".",
    },
    {
      key: "MAX",
      view: totals?.titles?.MAX,
      abbr: totals?.titles?.MAX.slice(0, 3) + ".",
    },
    {
      key: "CNT",
      view: totals?.titles?.CNT,
      abbr: totals?.titles?.CNT.slice(0, 4) + ".",
    },
    { key: "AVG", view: totals?.titles?.AVG, abbr: "MT" },
  ];

  let sumExists = false;
  let minExists = false;
  let maxExists = false;
  let cntExists = false;
  let avgExists = false;

  Object.entries(totals)
    .filter(([key, value]) => !["err", "titles"].includes(key))
    .forEach(([key, value]) => {
      if (Object.keys(value).includes("SUM")) {
        sumExists = true;
      }

      if (Object.keys(value).includes("MIN")) {
        minExists = true;
      }

      if (Object.keys(value).includes("MAX")) {
        maxExists = true;
      }

      if (Object.keys(value).includes("CNT")) {
        cntExists = true;
      }

      if (Object.keys(value).includes("AVG")) {
        avgExists = true;
      }
    });

  if (!sumExists) {
    totalOptions = totalOptions.filter((item) => item.key !== "SUM");
  }

  if (!minExists) {
    totalOptions = totalOptions.filter((item) => item.key !== "MIN");
  }

  if (!maxExists) {
    totalOptions = totalOptions.filter((item) => item.key !== "MAX");
  }

  if (!cntExists) {
    totalOptions = totalOptions.filter((item) => item.key !== "CNT");
  }

  if (!avgExists) {
    totalOptions = totalOptions.filter((item) => item.key !== "AVG");
  }

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  let columnsToUse = [{ F_ID: "blankCell", F_LIST: "Y" }, ...columns];

  const chooseDisplay = (value) => {
    if ([0, "0"].includes(value)) {
      return value;
    }

    if (!value || value === "") {
      return "-";
    }

    return value;
  };

  return (
    <TableRow
      key={selection}
      style={{
        backgroundColor: "#E5E4E2",
        position: "sticky",
        bottom: dense ? 0 : 0,
        zIndex: 5,
        display: !Object.keys(totals)?.length && "none",
      }}
    >
      <>
        {selection && (
          <TableCell
            key={`header - checkbox`}
            width={15}
            align="left"
            style={{
              backgroundColor: "#E5E4E2",
              position: "sticky",
              left: 0,
              zIndex: 5,
            }}
          ></TableCell>
        )}
        <Tooltip
          placement="bottom"
          color="#E5E4E2"
          overlayInnerStyle={{ color: "black" }}
          title={
            <Flex flexDirection={"column"}>
              {totalOptions.map((item, index) => (
                <div key={index} style={{ marginBottom: 3 }}>
                  <p style={{ width: "100%", fontSize: 10 }}>
                    {totals?.titles?.[item?.key]}
                  </p>
                  {index !== totalOptions?.length - 1 && <TotalsBottomBorder />}
                </div>
              ))}
            </Flex>
          }
        >
          <TableCell
            key={`totals-view`}
            width={35}
            style={{
              position: "sticky",
              left: selection ? 48 : 0,
              zIndex: 3,
              fontWeight: 600,
              fontSize: 10,
              paddingLeft: 10,
              paddingRight: 0,
              paddingTop: 3,
              paddingBottom: 0,
            }}
          >
            <Flex flexDirection={"column"}>
              {totalOptions.map((item, index) => (
                <div key={index} style={{ marginBottom: 3 }}>
                  <p style={{ width: "100%" }}>
                    {language === "EL" ? item?.abbr : item?.key}
                  </p>
                  {index !== totalOptions?.length - 1 && <TotalsBottomBorder />}
                </div>
              ))}
            </Flex>
          </TableCell>
        </Tooltip>

        <>
          {columnsToUse
            .filter((column) => viewRights[column.F_ID])
            .map(
              (item) =>
                item.F_LIST && (
                  <Tooltip
                    key={item?.F_ID}
                    placement="bottom"
                    color="#E5E4E2"
                    overlayInnerStyle={{ color: "black" }}
                    title={
                      !Object.keys(totals).includes(item?.F_ID) ? null : (
                        <Flex flexDirection={"column"}>
                          {totalOptions.map((option, index) => (
                            <div
                              key={index}
                              style={{
                                marginBottom: 3,
                                display:
                                  !totals?.[item?.F_ID]?.[option.key] &&
                                  totals?.[item?.F_ID]?.[option.key] !== 0 &&
                                  "none",
                              }}
                            >
                              <Flex flexDirection={"row"} style={{ gap: 5 }}>
                                <p
                                  style={{
                                    fontSize: 10,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {totals?.titles?.[option?.key]}:
                                </p>
                                <p
                                  style={{
                                    width: "100%",
                                    fontSize: 10,
                                  }}
                                >
                                  {totals?.[item?.F_ID]?.[option.key]}
                                </p>
                              </Flex>
                            </div>
                          ))}
                        </Flex>
                      )
                    }
                  >
                    <TableCell
                      key={`${item.F_ID}`}
                      width={`${(100 * item.F_WIDTH) / totalRowWidth}%`}
                      align={item.F_ALIGN.toLowerCase()}
                      style={
                        {
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 3,
                          paddingBottom: 0,
                        }

                        //   chooseTableCellStyle(
                        //   item?.F_ID,
                        //   frozenColumns,
                        //   "#E5E4E2",
                        //   selection,
                        //   columns,
                        //   "totals"
                        // )
                      }
                    >
                      {totalOptions.map((option, index) => (
                        <div key={index} style={{ marginBottom: 3 }}>
                          <p
                            style={{
                              width: "100%",
                              color:
                                !totals?.[item?.F_ID]?.[option.key] &&
                                totals?.[item?.F_ID]?.[option.key] !== 0 &&
                                "#E8E8E8",
                              //minWidth: "70px",
                              paddingLeft: 14,
                              paddingRight: 14,
                              fontSize: 10,
                            }}
                          >
                            {chooseDisplay(totals?.[item?.F_ID]?.[option.key])}
                          </p>
                          {index !== totalOptions?.length - 1 && (
                            <TotalsBottomBorder />
                          )}
                        </div>
                      ))}
                    </TableCell>
                  </Tooltip>
                )
            )}
        </>
      </>
    </TableRow>
  );
};

export default UltimateTableTotals;
