import "../../../css/ultimateTable.css";
import { Card, Layout, Col, Row, Progress } from "antd";
import Flex from "../../../../../helpers/components/Flex";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const { Content } = Layout;

const KPIsModalContent = ({ kpis }) => {
  const { width } = useWindowDimensions();

  const calculateValue = (min = 0, max = 0, value = 0) => {
    if (
      parseFloat(max) - parseFloat(min) === 0 ||
      parseFloat(max) - parseFloat(min) < 0
    ) {
      return parseFloat(value);
    } else {
      return (
        (100 * (parseFloat(max) - parseFloat(value))) /
        (parseFloat(max) - parseFloat(min))
      );
    }
  };

  return (
    <Content>
      <Card bodyStyle={{ maxHeight: "75vh", overflowY: "auto" }}>
        <Row gutter={[50, 30]} justify={width < 1450 ? "center" : "left"}>
          {kpis.map((item) => (
            <Col
              className="gutter-row"
              span={width < 1450 ? 20 : 10}
              key={item?.title}
            >
              <Flex
                flexDirection={"column"}
                style={{
                  gap: 15,
                  padding: 15,
                  width: 200,
                }}
              >
                <p
                  style={{
                    width: 200,
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {item?.title}
                </p>
                <Flex
                  style={{ width: 200, justifyContent: "center", heigth: 80 }}
                >
                  <Progress
                    type={"dashboard"}
                    steps={5}
                    percent={calculateValue(item?.min, item?.max, item?.value)}
                    format={() => item?.value}
                    trailColor={"rgba(0, 0, 0, 0.06)"}
                    strokeColor={item?.color}
                    strokeWidth={20}
                    size={[150, 20]}
                  />
                </Flex>
                <Flex
                  flexDirection={"row"}
                  style={{
                    width: 200,
                    gap: 40,
                    justifyContent: "center",
                    marginTop: -30,
                    fontSize: 12,
                  }}
                >
                  <p>{item?.min}</p>
                  <p>{item?.max}</p>
                </Flex>
              </Flex>
            </Col>
          ))}
        </Row>
      </Card>
    </Content>
  );
};

export default KPIsModalContent;
