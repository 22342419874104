import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAuthStore,
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";
import { Radio, Space } from "antd";

const Interpolation = () => {
  const interpolation = useChartStore((state) => state.interpolation);
  const setInterpolation = useChartStore((state) => state.setInterpolation);
  const areaDisplay = useChartStore((state) => state.areaDisplay);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const [options, setOptions] = useState([]);

  useEffect(
    () =>
      setOptions([
        { value: "natural", label: `${commonTitles.smooth}` },
        { value: "linear", label: `${commonTitles.linear}` },
      ]),
    [language, commonTitles]
  );

  return (
    <FormControl sx={{ color: "rgb(8, 8, 46)", mt: 2, fontSize: "14px" }}>
      <FormLabel
        sx={{ color: "rgb(84, 190, 243)", fontSize: "12px", mb: "10px" }}
        id="demo-row-radio-buttons-group-label"
      >
        {commonTitles.interpolation}
      </FormLabel>
      <Radio.Group
        onChange={(e) => setInterpolation(e.target.value)}
        disabled={areaDisplay && true}
        value={areaDisplay ? "linear" : interpolation}
      >
        <Space direction="horizontal" style={{ gap: 2 }}>
          {options.map(({ value, label }) => (
            <Radio key={value} value={value}>
              <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{label}</p>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </FormControl>
  );
};

export default Interpolation;
