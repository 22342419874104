import React from "react";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import {
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";
import { Directions } from "@mui/icons-material";
import Flex from "../../../../helpers/components/Flex";

function valuetext(value) {
  return `${value}`;
}

const radiusMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.15,
    label: "0.15",
  },
  {
    value: 0.3,
    label: "0.3",
  },
  {
    value: 0.45,
    label: "0.45",
  },
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.75,
    label: "0.75",
  },
];

const useStyles = makeStyles((theme) => ({
  mark: {
    color: "rgb(8, 8, 46)",
    fontSize: 12,
    marginTop: -6,
  },

  valueLabelDisplay: {
    color: "white",
    background: "rgb(8, 8, 46)",
    fontSize: 12,
  },
}));

const InnerRadius = () => {
  const classes = useStyles();
  const radialDisplay = useChartStore((state) => state.radialDisplay);
  const is3D = useChartStore((state) => state.is3D);
  const innerRadius = useChartStore((state) => state.innerRadius);
  const setInnerRadius = useChartStore((state) => state.setInnerRadius);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Flex flexDirection={"column"} style={{ marginTop: 15 }}>
      <p style={{ fontSize: 12, color: "rgb(84, 190, 243)" }}>
        {commonTitles.innerRadius}
      </p>
      <Slider
        sx={{ width: 160, mr: 10, mt: 0 }}
        size="small"
        aria-label="Inner Radius"
        value={is3D && !radialDisplay ? 0 : innerRadius}
        onChange={(e) => setInnerRadius(e.target.value)}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        disabled={is3D && !radialDisplay && true}
        step={0.05}
        marks={radiusMarks}
        classes={{
          markLabel: classes.mark,
          valueLabel: classes.valueLabelDisplay,
        }}
        min={0}
        max={0.6}
      />
    </Flex>
  );
};

export default InnerRadius;
