import React from "react";
import {
  useAuthStore,
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { Select } from "antd";
import Flex from "../../../../helpers/components/Flex";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const GroupField = ({ columns, privileges, groupFieldOptions }) => {
  //const capitilizedGroupFieldOptions = capitalizeWords(groupFieldOptions);
  const role = useAuthStore((state) => state.role);
  const groupField = useChartStore((state) => state.groupField);
  const setGroupField = useChartStore((state) => state.setGroupField);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  return (
    <Flex flexDirection={"column"} style={{ gap: 5 }}>
      <p style={{ color: "rgb(8, 8, 46)", fontWeight: 500, fontSize: 12 }}>
        {commonTitles.groupfield}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 150,
            marginLeft: width < 1525 ? 0 : 0,
            marginRight: 10,
            marginTop: width < 1525 ? 0 : -50,
            marginBottom: -50,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{
            minWidth: "max-content",
            paddingLeft: 5,
            paddingRight: 5,
            overflowY: "auto",
          }}
          onChange={(e) => setGroupField(e)}
          defaultValue={groupField}
          placeholder={`${commonTitles.groupfield}`}
          virtual={false}
        >
          <Option className="option" key={""} value={""} />
          {groupFieldOptions.map(({ id, view }) => (
            <Option className="option" key={`${id}-${view}`} value={id}>
              {view}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default GroupField;
