import { useChartStore } from "../../../store/useStores";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import { BarChartOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const BarChartButton = () => {
  const { width } = useWindowDimensions();
  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);

  return (
    <Tooltip placement={width < 1525 ? "bottom" : "right"} title={"Bar chart"}>
      <BarChartOutlined
        style={{
          backgroundColor: chartType === "BarChart" && "rgb(84, 190, 243)",
          color: chartType === "BarChart" ? "#fff" : "rgb(8, 8, 46)",
          width: "max-content",
          padding: 8,
          fontSize: "30px",
        }}
        onClick={() => setChartType("BarChart")}
      />
    </Tooltip>
  );
};

export default BarChartButton;
