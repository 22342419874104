import UltimateTable from "../../ultimateTable/UltimateTable";
import LoadingCard from "./loadingCard/LoadingCard";
import NoAccessDisplay from "./noAccessDisplay/NoAccessDisplay";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
import { useEffect } from "react";
import { useModuleStore } from "../../../store/useStores";

const DisplaySelector = ({
  moduleParams = {},
  //columnData = [],
  serverColumns = [],
  data = [],
  filters = {},
  staticOptions = {},
  foreignKeyValues = {},
  //privileges = {},
  canView,
  canInsert,
  canUpdate,
  canDelete,
  updateData,
  deleteData,
  insertData,
  updateFilters,
  updateOrderConfig,
  moduleID,
  updateCurrentPage,
  updatePageSize,
  changeDisplayedColumns,
  totalRowWidth = 1,
  isLoading = false,
  isProjectSpecific,
  //existingRowIDs,
  totals = {},
  updateTotals,
  //frozenColumns = [],
  //updateFrozenColumns,
  kpis = [],
}) => {
  const showTotals = useModuleStore((state) => state.showTotals);
  const setShowTotals = useModuleStore((state) => state.setShowTotals);

  const updateShowTotals = (value) => {
    setShowTotals(value);
  };

  useEffect(() => {
    if (!Object.keys(totals).length && !showTotals) {
      setShowTotals(false);
    }
  }, []);

  let columnData = [];
  columnData =
    serverColumns
      // ?.filter((field) => field.F_LIST === "Y")
      .map((field) => ({
        ...field,
        T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
      })) ?? [];

  let hasAnyUpdatePrivileges = false;
  let hasAnyInsertPrivileges = false;
  columnData.forEach((field) => {
    if (field?.canupdate || field?.canupdateifnull) {
      hasAnyUpdatePrivileges = true;
    }
    if (field?.caninsert) {
      hasAnyInsertPrivileges = true;
    }
  });

  const privileges = {
    canDelete: canDelete,
    canInsert: canInsert || hasAnyInsertPrivileges ? true : false,
    canCopy: canInsert,
    canUpdate: canUpdate || hasAnyUpdatePrivileges ? true : false,
    canView: canView,
  };

  if (isLoading) {
    return <LoadingCard />;
  }

  if (privileges?.canView) {
    return (
      <UltimateTable
        key={columnData.length}
        title={moduleParams?.T_TITLE ?? ""}
        columns={columnData}
        data={{ ...data }}
        filters={filters ?? {}}
        staticOptions={staticOptions ?? {}}
        foreignKeyValues={foreignKeyValues ?? {}}
        privileges={privileges ?? {}}
        updateData={updateData}
        deleteData={deleteData}
        insertData={insertData}
        updateFilters={updateFilters}
        updateOrderConfig={updateOrderConfig}
        moduleID={moduleID}
        config={"module"}
        updateCurrentPage={updateCurrentPage}
        updatePageSize={updatePageSize}
        changeDisplayedColumns={changeDisplayedColumns}
        totalRowWidth={totalRowWidth}
        isProjectSpecific={isProjectSpecific ?? true}
        existingRowIDs={Object.keys(data) ?? []}
        totals={totals}
        updateTotals={updateTotals}
        showTotals={showTotals}
        updateShowTotals={updateShowTotals}
        // frozenColumns={frozenColumns}
        // updateFrozenColumns={updateFrozenColumns}
        kpis={kpis}
      />
    );
  } else {
    return <NoAccessDisplay />;
  }
};

export default DisplaySelector;
