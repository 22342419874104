import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Banner from "./banner/Banner";
import Navigation from "../navigation/Navigation";
import "./css/module.css";
import {
  useFilterStore,
  useMenuStore,
  useAlertStore,
  usePaginationStore,
  useModuleStore,
  useCommonTitlesStore,
} from "../../store/useStores";
import apiGetData from "../../api/endpoints/apiGetData";
import { useAuthStore } from "../../store/useStores";
import createData from "./helpers/createData";
import createFilters from "./helpers/createFilters";
import filterDataOnSearch from "./helpers/filterDataOnSearch";
import Flex from "../../helpers/components/Flex";
import htmlCodesRemover from "../ultimateTable/helpers/htmlCodesRemover";
import initializeFilters from "./helpers/initializeFilters";
import createModuleParams from "./helpers/createModuleParams";
import countTotalRowWidth from "./helpers/countTotalRowWidth";
import DisplaySelector from "./displaySelector/DisplaySelector";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";
import ModuleWrapper from "./wrappers/ModuleWrapper";
import TableWrapper from "../../helpers/components/TableWrapper";
import axios from "axios";
import apiGetModuleFields from "../../api/endpoints/apiGetModuleFields";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const Module = () => {
  const { moduleID } = useParams();
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const setShowGroup = useAuthStore((state) => state.setShowGroup);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const menu = useMenuStore((state) => state.menu);

  const [responseData, setResponseData] = useState({});

  const [columnData, setColumnData] = useState([]);
  const [data, setData] = useState({});
  const [filterStatus, setFilterStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pageSize = usePaginationStore((state) => state.pageSize);
  const setPageSize = usePaginationStore((state) => state.setPageSize);
  const currentPage = usePaginationStore((state) => state.currentPage);
  const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

  const filters = useFilterStore((state) => state.filters);
  const setFilters = useFilterStore((state) => state.setFilters);

  const alertsChanged = useAlertStore((state) => state.alertsChanged);
  const setAlertsChanged = useAlertStore((state) => state.setAlertsChanged);

  const allData = useModuleStore((state) => state.allData);
  const setAllData = useModuleStore((state) => state.setAllData);
  // const restData = useModuleStore((state) => state.restData);
  // const setRestData = useModuleStore((state) => state.setRestData);
  const orderBy = useModuleStore((state) => state.orderBy);
  const setOrderBy = useModuleStore((state) => state.setOrderBy);
  const orderDirection = useModuleStore((state) => state.orderDirection);
  const setOrderDirection = useModuleStore((state) => state.setOrderDirection);
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);
  // const frozenColumns = useModuleStore((state) => state.frozenColumns);
  // const setFrozenColumns = useModuleStore((state) => state.setFrozenColumns);

  const location = useLocation();

  const invertFilterStatus = () => {
    setFilterStatus(!filterStatus);
  };

  useEffect(() => {
    setShowGroup("");
    // setPageSize(20);
    setCurrentPage(1);
    // setOrderBy("");
    // setOrderDirection("ASC");

    // if (!Object.keys(frozenColumns).includes(moduleID)) {
    //   setFrozenColumns({
    //     ...frozenColumns,
    //     [moduleID]: [],
    //   });
    // }
  }, [moduleID]);

  let moduleParams = {};
  let totalRowWidth = 1;

  useEffect(async () => {
    setIsLoading(true);

    let callStartTime = performance.now();

    const { rows, rows1, ...response } = await apiGetData(
      moduleID,
      baseURL,
      filters[moduleID] ? filters[moduleID] : {},
      orderBy,
      orderDirection?.[moduleID] ?? "ASC",
      pageSize?.[moduleID] ?? 20,
      currentPage
    );

    let callEndTime = performance.now();
    let callDuration = (callEndTime - callStartTime) / 1000;
    console.log("Call duration: ", callDuration, " s");

    let createDataStartTime = performance.now();

    setResponseData(response);

    if (!orderBy?.[moduleID]) {
      setOrderBy({ ...orderBy, [moduleID]: response?.fields?.[0]?.F_ID });
    }

    // const tempData = createData(
    //   rows,
    //   response?.fields,
    //   response?.fkvalues,
    //   response?.staticoptions,
    //   baseURL,
    //   dateTypes
    // );

    // setData(tempData);
    // setAllData(tempData);

    setData(rows1);
    setAllData(rows1);

    let createDataEndTime = performance.now();
    let createDataDuration = (createDataEndTime - createDataStartTime) / 1000;
    console.log("Create data duration: ", createDataDuration, " s");

    totalRowWidth = countTotalRowWidth(response?.fields);
    moduleParams = createModuleParams(menu, response?.menu?.items, moduleID);

    let totalEndTime = performance.now();
    let totalDuration = (totalEndTime - callStartTime) / 1000;
    console.log("Total duration: ", totalDuration, " s");

    setIsLoading(false);
  }, [
    moduleID,
    filterStatus,
    location.key,
    language,
    // pageSize,
    // currentPage,
    // orderBy,
    // orderDirection,
  ]);

  useEffect(() => {
    //moduleParams = createModuleParams(menu, restData.paramMenu, moduleID);
    moduleParams = createModuleParams(
      menu,
      responseData?.menu?.items,
      moduleID
    );
  }, [menu, responseData.menu, moduleID]);

  useEffect(() => {
    totalRowWidth = countTotalRowWidth(responseData?.fields);
  }, [columnData]);

  //moduleParams = createModuleParams(menu, restData.paramMenu, moduleID);
  moduleParams = createModuleParams(menu, responseData?.menu?.items, moduleID);
  totalRowWidth = countTotalRowWidth(responseData?.fields);

  const updateSearchValue = (change) => {
    const tempData = filterDataOnSearch(allData, change, responseData?.fields);

    if (change) {
      setData({ ...tempData });
    } else {
      setData(allData);
    }
  };

  const updateFilters = (changes, config) => {
    if (config === "update") {
      const tempfilters = createFilters(
        filters[moduleID],
        changes,
        responseData?.fields,
        numberTypes
      );
      setFilters({ [moduleID]: { ...tempfilters } });
    }

    if (config === "erase") {
      setFilters({ [moduleID]: {} });
    }

    invertFilterStatus();
  };

  const insertData = (changes) => {
    setData({ ...allData, ...changes });
    setAllData({ ...allData, ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const deleteData = (changes) => {
    setData({ ...changes });
    setAllData({ ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const updateData = (changes) => {
    setData({ ...data, ...changes });
    setAllData({ ...allData, ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const updateTotals = (value) => {
    setResponseData({
      ...responseData,
      totals: { ...responseData?.totals, ...value },
    });
  };

  const updateOrderConfig = (id, direction) => {
    if (id && direction === null) {
      setOrderBy({ ...orderBy, [moduleID]: "" });
      setOrderDirection({ ...orderDirection, [moduleID]: "" });
    }

    if (id) {
      setOrderBy({ ...orderBy, [moduleID]: id });
      setOrderDirection({ ...orderDirection, [moduleID]: direction });
    }
  };

  const updateCurrentPage = (page, size) => {
    setCurrentPage(page);
    setPageSize({ ...pageSize, [moduleID]: size });
  };

  const updatePageSize = (size) => {
    setPageSize({ ...pageSize, [moduleID]: size });

    if (Math.ceil(Object.values(data).length / size) < currentPage) {
      setCurrentPage(Math.ceil(Object.values(data).length / size));
    }
  };

  const changeDisplayedColumns = async (key, viewRights) => {
    const columnsTemp = responseData?.fields
      .filter((column) => viewRights[column.F_ID])
      .map((column) => {
        let isImportant = column.F_LIST;

        if (key === "") {
          isImportant = "Y";
        } else {
          if (column.F_ID === key) {
            isImportant = column.F_LIST === "Y" ? "" : "Y";
          }
        }

        return { ...column, F_LIST: isImportant };
      });

    if (key) {
      const serverData = await apiGetModuleFields(baseURL, moduleID);

      const tempData = serverData.rows.map((item) => ({
        ...item,
        T_TITLE: htmlCodesRemover(item.T_TITLE) ?? item.T_TITLE,
      }));

      let changes = {};

      const columnToEdit = tempData.filter((item) => item.F_ID === key)?.[0];
      changes = {
        ...columnToEdit,
        F_LIST:
          responseData?.fields.filter((column) => column.F_ID === key)?.[0]
            ?.F_LIST === "Y"
            ? "N"
            : "Y",
      };

      const url = `${baseURL}/App_fields_api/save`;

      console.log("changes: ", changes);

      try {
        const response = await axios.post(
          url,
          {
            id: key,
            ...changes,
          },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        //console.log("App_fields_api/save: response: ", response?.data);

        if (response?.data?.STATUS === "Success") {
          //console.log("F_LIST changed");
        } else {
          const responseMsg = response?.data?.MESSAGE ?? {};
          let template = commonTitles[responseMsg.ID];

          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });
          displayResponseMessage(template, 5, "warning");
        }
      } catch (err) {
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }
    }

    setResponseData({ ...responseData, fields: columnsTemp });
    //setColumnData(columnsTemp);
  };

  useEffect(() => {
    const tempfilters = initializeFilters(responseData?.filter);
    setFilters({ [moduleID]: { ...tempfilters } });
  }, [responseData?.filter]);

  // const updateFrozenColumns = (value) => {
  //   if (frozenColumns?.[moduleID].includes(value)) {
  //     let temp = frozenColumns?.[moduleID]?.filter((item) => item !== value);
  //     setFrozenColumns({ ...frozenColumns, [moduleID]: temp });
  //   } else {
  //     let temp = responseData?.fields
  //       .filter(
  //         (column) =>
  //           frozenColumns?.[moduleID].includes(column?.F_ID) ||
  //           value === column?.F_ID
  //       )
  //       .map((item) => item?.F_ID);
  //     setFrozenColumns({ ...frozenColumns, [moduleID]: temp });
  //   }
  // };

  // const kpis = [
  //   { value: 10, min: 0, max: 25, title: "KPI 1", color: "#FF0000" },
  //   { value: 45, min: 0, max: 100, title: "KPI 2", color: "#FFFF00" },
  //   { value: 1.5, min: 1, max: 2, title: "KPI 3", color: "#0000FF" },
  // ];

  return (
    <Flex
      key={`${location.key} - ${responseData?.fields?.length}`}
      flexDirection={"column"}
    >
      <Navigation
        // paramMenu={restData?.paramMenu ?? []}
        // paramMenuTitle={restData?.paramMenuTitle ?? ""}
        paramMenu={responseData?.menu?.items ?? []}
        paramMenuTitle={responseData?.menu?.title ?? ""}
      />
      <BackgroundWrapper>
        <ModuleWrapper>
          <Banner
            key={`${orderBy?.[moduleID] ?? ""}-${
              orderDirection?.[moduleID] ?? "ASC"
            }-${moduleID}-${moduleParams?.T_TITLE} - ${
              Object.keys(responseData)?.length
            }`}
            title={moduleParams?.T_TITLE ?? ""}
            path={`/module/${moduleParams?.ME_MODULEID ?? ""}`}
            serverColumns={responseData?.fields}
            //staticOptions={restData?.staticOptions ?? {}}
            staticOptions={responseData?.staticoptions ?? {}}
            foreignKeyValues={responseData?.fkvalues ?? {}}
            //foreignKeyValues={restData?.foreignKeyValues ?? {}}
            //privileges={restData?.privileges ?? {}}
            canView={responseData?.canview}
            canInsert={responseData?.caninsert}
            canUpdate={responseData?.canupdate}
            canDelete={responseData?.candelete}
            insertData={insertData}
            moduleID={moduleID}
            updateSearchValue={updateSearchValue}
            isProjectSpecific={
              responseData?.projectspecific === "N" ? false : true
            }
            //existingRowIDs={Object.keys(data) ?? []}
            data={data}
            //isProjectSpecific={restData?.isProjectSpecific ?? true}
            //existingRowIDs={restData?.existingRowIDs ?? []}
            updateTotals={updateTotals}
            showEmailTriggerButton={responseData?.emailbutton ?? false}
          />
          <TableWrapper>
            <DisplaySelector
              key={responseData?.fields?.length}
              moduleParams={moduleParams}
              serverColumns={responseData?.fields}
              data={{ ...data }}
              filters={filters[moduleID] ? filters[moduleID] : {}}
              // staticOptions={restData?.staticOptions ?? {}}
              // foreignKeyValues={restData?.foreignKeyValues ?? {}}
              staticOptions={responseData?.staticoptions ?? {}}
              foreignKeyValues={responseData?.fkvalues ?? {}}
              //privileges={restData?.privileges ?? {}}
              canView={responseData?.canview}
              canInsert={responseData?.caninsert}
              canUpdate={responseData?.canupdate}
              canDelete={responseData?.candelete}
              updateData={updateData}
              deleteData={deleteData}
              insertData={insertData}
              updateFilters={updateFilters}
              updateOrderConfig={updateOrderConfig}
              moduleID={moduleID}
              updateCurrentPage={updateCurrentPage}
              updatePageSize={updatePageSize}
              changeDisplayedColumns={changeDisplayedColumns}
              totalRowWidth={totalRowWidth}
              isLoading={isLoading}
              // isProjectSpecific={restData?.isProjectSpecific ?? true}
              // existingRowIDs={restData?.existingRowIDs ?? []}
              isProjectSpecific={
                responseData?.projectspecific === "N" ? false : true
              }
              //existingRowIDs={[]}
              totals={responseData?.totals}
              updateTotals={updateTotals}
              // frozenColumns={frozenColumns?.[moduleID]}
              // updateFrozenColumns={updateFrozenColumns}
              kpis={responseData?.kpis ?? []}
            />
          </TableWrapper>
        </ModuleWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};
export default Module;
