import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Welcome from "./components/welcome/Welcome";
import { useEffect } from "react";
import Home from "./components/home/Home";
import Module from "./components/module/Module";
import SafetyAudit from "./components/safetyAudit/SafetyAudit";
import Profile from "./components/profile/Profile";
import {
  useAlertStore,
  useAuthStore,
  useCommonTitlesStore,
  useMenuStore,
  useModTitlesStore,
} from "./store/useStores";
import UltimateChart from "./components/ultimateChart/UltimateChart";
import apiGetAlerts from "./api/endpoints/apiGetAlerts";
import apiGetAlertTitles from "./api/endpoints/apiGetAlertTitles";
import apiGetMenu from "./api/endpoints/apiGetMenu";
import apiGetTitles from "./api/endpoints/apiGetTitles";
import apiGetCommonTitles from "./api/endpoints/apiGetCommonTitles";
import AdminMenu from "./components/administration/menu/AdminMenu";
import AlertConfig from "./components/administration/alertConfig/AlertConfig";
import UserRoles from "./components/administration/userRoles/UserRoles";
import Users from "./components/administration/users/Users";
import Languages from "./components/administration/languages/Languages";
import NoMatchRoute from "./components/noMatchRoute/NoMatchRoute";
import AccessNotAllowed from "./components/noMatchRoute/AccessNotAllowed";
import ChangePassword from "./components/welcome/header/changePassword/ChangePassword";
import ModulesConfig from "./components/administration/modules/ModulesConfig";
import FieldsConfig from "./components/administration/modules/fields/FieldsConfig";
import ProjectsConfig from "./components/administration/projects/ProjectsConfig";
import ClientsConfig from "./components/administration/clients/ClientsConfig";
import ConsultantsConfig from "./components/administration/consultants/ConsultantsConfig";
import SitesConfig from "./components/administration/sites/SitesConfig";
import AdminTitles from "./components/administration/systemTitles/AdminTitles";
import EmailConfig from "./components/administration/emails/EmailConfig";
import UltimateChartBackend from "./components/ultimateChartBackend/UltimateChartBackend";

const App = () => {
  const coreChange = useAuthStore((state) => state.coreChange);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const projectID = useAuthStore((state) => state.projectID);
  const loginStatus = useAuthStore((state) => state.loginStatus);
  const darkTheme = useAuthStore((state) => state.darkTheme);
  const setNavigationColor = useAuthStore((state) => state.setNavigationColor);
  const setNavigationTextColor = useAuthStore(
    (state) => state.setNavigationTextColor
  );
  const setBannerColor = useAuthStore((state) => state.setBannerColor);
  const setBannerTextColor = useAuthStore((state) => state.setBannerTextColor);
  const setBannerPathColor = useAuthStore((state) => state.setBannerPathColor);

  window.onbeforeunload = function () {
    localStorage.clear();
  };

  const setCommonTitles = useCommonTitlesStore(
    (state) => state.setCommonTitles
  );

  const setModTitles = useModTitlesStore((state) => state.setModTitles);

  const setAlertsAndTitles = useAlertStore((state) => state.setAlertsAndTitles);
  const alertsChanged = useAlertStore((state) => state.alertsChanged);

  //const menu = useMenuStore((state) => state.menu);
  //const languages = useMenuStore((state) => state.languages);
  const setMenu = useMenuStore((state) => state.setMenu);
  const setLanguages = useMenuStore((state) => state.setLanguages);
  const setAdminMenu = useMenuStore((state) => state.setAdminMenu);
  const setProjects = useMenuStore((state) => state.setProjects);
  const setUsername = useMenuStore((state) => state.setUsername);

  let { moduleID } = useParams();

  useEffect(async () => {
    if (workspaceID) {
      apiGetTitles(workspaceID, projectID, language, setModTitles, baseURL);
      const { menuData, languages, projects, adminMenu, username } =
        await apiGetMenu(baseURL);
      setMenu(menuData);
      setLanguages(languages);
      setAdminMenu(adminMenu);
      setProjects(projects);
      setUsername(username);

      const alertsData = await apiGetAlerts(baseURL);

      const alertTitlesData = await apiGetAlertTitles(
        workspaceID,
        language,
        baseURL
      );

      setAlertsAndTitles(alertsData, alertTitlesData);
    }

    apiGetCommonTitles(language, setCommonTitles, baseURL);
  }, [projectID, language, coreChange]);

  useEffect(async () => {
    const alertsData = await apiGetAlerts(baseURL);

    const alertTitlesData = await apiGetAlertTitles(
      workspaceID,
      language,
      baseURL
    );

    setAlertsAndTitles(alertsData, alertTitlesData);
  }, [alertsChanged]);

  useEffect(() => {
    if (darkTheme) {
      setNavigationColor("#02081b");
      setNavigationTextColor("rgb(255, 255, 255)");
      setBannerColor("rgb(8, 8, 46)");
      setBannerTextColor("rgb(255, 255, 255)");
      setBannerPathColor("rgb(84, 190, 243)");
    } else {
      setNavigationColor("#e9eaec");
      setNavigationTextColor("#02081b");
      setBannerColor("#a0afb7");
      setBannerTextColor("rgb(6, 97, 143)");
      setBannerPathColor("rgb(6, 97, 143)");
    }
  }, [darkTheme]);

  //console.log("App: languages: ", languages);

  if (loginStatus === "Success") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/config/menu" element={<AdminMenu />} />
          <Route path="/config/alerts" element={<AlertConfig />} />
          <Route path="/config/emails" element={<EmailConfig />} />
          <Route path="/config/user_roles" element={<UserRoles />} />
          <Route path="/config/users" element={<Users />} />
          <Route path="/config/languages" element={<Languages />} />
          <Route path="/config/modules" element={<ModulesConfig />} />
          <Route path="/config/modules/:id" element={<FieldsConfig />} />
          <Route path="/config/projects" element={<ProjectsConfig />} />
          <Route path="/config/clients" element={<ClientsConfig />} />
          <Route path="/config/consultants" element={<ConsultantsConfig />} />
          <Route path="/config/sites" element={<SitesConfig />} />
          <Route path="/config/system_titles" element={<AdminTitles />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/module/:moduleID/metrics"
            element={<UltimateChart key={`${moduleID}/metrics`} />}
          />
          <Route
            path="/module/:moduleID/metrics-backend"
            element={
              <UltimateChartBackend key={`${moduleID}/metrics-backend`} />
            }
          />
          <Route path="/module/:moduleID" element={<Module key={moduleID} />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/safetyAudit" element={<SafetyAudit />} /> */}
          <Route path="/" element={<Welcome />} />
          <Route path="*" element={<NoMatchRoute />} />
        </Routes>
      </BrowserRouter>
    );
  } else if (loginStatus === "ChangePassword") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/" element={<Welcome />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="*" element={<AccessNotAllowed />} />
        </Routes>
      </BrowserRouter>
    );
  }
};

export default App;
